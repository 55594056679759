import React, { FC, useState, useEffect } from 'react'
import { jsPDF } from 'jspdf'
import { useNavigate } from 'react-router'
import Camera, { FACING_MODES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import './CreateFile.scss'
import ModalFormCreate from '../Modal/ModalFormCreate/ModalFormCreate'
import { useAppDispatch, useAppSelector, useQuery } from '../../hooks'
import { useAuth } from '../../hooks/useAuth'
import { Button } from 'antd-mobile-v5'
import { createFile } from '../../store/actions/file'
import { setPaywall } from '../../store/reducers/paywall'
import dayjs from 'dayjs'
import PaywallSaveDocument from '../Paywall/PaywallSaveDocument/PaywallSaveDocument'
import PaywallPremium from '../Paywall/PaywallPremium/PaywallPremium'
import PaywallPremiumUpdate from '../Paywall/PaywallPremiumUpdate/PaywallPremiumUpdate'
import { getCountFiles } from '../../store/actions/profile'

type FileType = {
  url: string
  width: number
  height: number
}

type PropsType = {}

const getMeta = async (url: string) => {
  const img = new Image()
  img.src = url
  await img.decode()
  return img
}

const CreateFile: FC<PropsType> = () => {
  const { user } = useAuth()
  const profile = useAppSelector((state) => state.profile)
  const paywall = useAppSelector((state) => state.paywall)
  const [isModalCreate, setIsModalCreate] = useState<boolean>(false)
  const [isPaywallSaveDocument, setIsPaywallSaveDocument] =
    useState<boolean>(false)
  const [isPaywallPremium, setIsPaywallPremium] = useState<boolean>(false)
  const [isPaywallPremiumUpdate, setIsPaywallPremiumUpdate] =
    useState<boolean>(false)
  const [files, setFiles] = useState<FileType[]>([])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const query = useQuery()
  const category_id: string | null = query.get('category_id')
  const folder_id: string | null = query.get('folder_id')

  const isPremium = dayjs(profile.active_till).diff(dayjs(), 'minute') > 0

  const redirectToFile = (id: string) => {
    navigate(`/file/${id}`)
  }

  const handlePremiumSuccess = () => {
    setIsPaywallPremium(false)
    setIsModalCreate(true)
  }

  const handlePremiumClose = () => {
    setIsPaywallPremium(false)
    setIsModalCreate(true)
  }

  const handlePremiumUpdateSuccess = () => {
    setIsPaywallPremiumUpdate(false)
  }

  const handlePremiumUpdateClose = () => {
    setIsPaywallPremiumUpdate(false)
  }

  const handleTakePhoto = async (dataUri: string) => {
    const img = await getMeta(dataUri)

    setFiles((prev) => [
      ...prev,
      {
        url: dataUri,
        width: img.naturalWidth,
        height: img.naturalHeight,
      },
    ])
  }

  const handleSave = async () => {
    const countCreatedFiles = await dispatch(getCountFiles())

    if (countCreatedFiles > profile.files) {
      if (profile.active_till) {
        setIsPaywallPremiumUpdate(true)
      } else {
        setIsPaywallPremium(true)
      }
    }

    if (!profile.active_till) {
      if (files.length > 5) {
        setIsPaywallSaveDocument(true)
      } else {
        setIsPaywallPremium(true)
      }

      return
    } else if (!isPremium) {
      setIsPaywallPremiumUpdate(true)
      return
    }

    setIsModalCreate(true)
  }

  const handleCreate = async (value: string) => {
    let file = files[0].url

    if (files.length > 1) {
      const doc = new jsPDF({
        unit: 'px',
      })

      files.forEach((file) => {
        const page = doc.addPage([file.width, file.height])
        page.addImage(file.url, 'JPEG', 0, 0, file.width, file.height)
      })

      doc.deletePage(1)
      file = doc.output('datauristring')

      if (category_id) {
        const { id } = await dispatch(
          createFile({
            title: value,
            data_uri: file,
            category_id: category_id,
            parent_id: folder_id,
            user_id: user.id,
            extension: 'pdf',
          })
        )

        redirectToFile(id)

        return
      }
    }

    if (category_id) {
      const { id } = await dispatch(
        createFile({
          title: value,
          data_uri: file,
          category_id: category_id,
          parent_id: folder_id,
          user_id: user.id,
          extension: 'jpg',
        })
      )

      redirectToFile(id)
    }
  }

  return (
    <>
      <div className="create-file">
        <Camera
          imageType="jpg"
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          isFullscreen={true}
          imageCompression={0}
          isMaxResolution={true}
          onTakePhoto={handleTakePhoto}
        />

        {files.length && (
          <Button
            style={{ position: 'fixed', right: 20, bottom: 75 }}
            onClick={handleSave}
          >
            Save
          </Button>
        )}
      </div>

      <ModalFormCreate
        visible={isModalCreate}
        title="File name"
        text="Enter a name for your document"
        placeholder="File Name"
        onSubmit={handleCreate}
        onClose={() => setIsModalCreate(false)}
      />

      <PaywallSaveDocument
        isVisible={isPaywallSaveDocument}
        count={files.length}
        onSuccess={() => {}}
        onClose={() => setIsPaywallSaveDocument(false)}
      />

      <PaywallPremium
        isVisible={isPaywallPremium}
        onSuccess={handlePremiumSuccess}
        onClose={handlePremiumClose}
      />

      <PaywallPremiumUpdate
        isVisible={isPaywallPremiumUpdate}
        onSuccess={handlePremiumUpdateSuccess}
        onClose={handlePremiumUpdateClose}
      />
    </>
  )
}

export default CreateFile
