import {
  addDoc,
  deleteDoc,
  doc,
  endAt,
  getDoc,
  getDocs,
  orderBy,
  query,
  startAt,
  updateDoc,
  where,
} from 'firebase/firestore'
import { createCollection, storage } from '../../firebase'
import { AppDispatchType } from '../index'
import { IFile, IFileCreate } from '../../types'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import { addFile, removeFile, setFileList, updateFile } from '../reducers/file'
import { SelectedType } from '../../components/MoveTo/MoveTo'
import { supabase } from '../../supabase'
import { dataURLtoFile } from '../../helpers'
import { ErrorInfo } from 'react'
import { PostgrestError } from '@supabase/supabase-js'

const COLLECTION_FILE: string = 'files'
const TABLE_NAME: string = 'files'
const STORAGE_NAME: string = 'files'

export const getFileList =
  (parent_id: string | null) =>
  async (dispatch: AppDispatchType): Promise<IFile[]> => {
    try {
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .select()
        .eq('parent_id', parent_id)

      if (error) throw new Error(error.message)

      dispatch(setFileList(data))

      return data
    } catch (e) {
      console.log(e)

      return []
    }
  }

export const getFile = (id: string) => async (dispatch: AppDispatchType) => {
  const { data, error } = await supabase
    .from(TABLE_NAME)
    .select()
    .eq('id', id)
    .single()

  return data
}

export const createFile =
  ({
    title,
    data_uri = '',
    category_id,
    parent_id,
    user_id,
    extension,
  }: IFileCreate) =>
  async (dispatch: AppDispatchType) => {
    try {
      const file = dataURLtoFile(data_uri, title)

      const upload = await supabase.storage
        .from(STORAGE_NAME)
        .upload(
          `${user_id}/${parent_id || category_id}/${title}.${extension}`,
          file
        )

      const response = await supabase
        .from(TABLE_NAME)
        .insert({
          title,
          user_id,
          category_id,
          extension,
          parent_id: parent_id || category_id,
          size: file.size,
          url: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/files/${upload.data?.path}`,
        })
        .select()
        .single()

      if (response.error) {
        throw new Error(response.error.message)
      }

      if (response.status === 201) {
        dispatch(addFile(response.data))
      }

      return response.data
    } catch (e) {
      console.log(e)
    }
  }

export const renameFile =
  (id: string, title: string) => async (dispatch: AppDispatchType) => {
    try {
      await updateDoc(doc(createCollection<IFile>(COLLECTION_FILE), id), {
        title,
      })

      dispatch(updateFile({ id, title }))
    } catch (error) {
      console.log(error)
    }
  }

export const moveFile =
  (id: string, move: SelectedType) => async (dispatch: AppDispatchType) => {
    try {
      await updateDoc(doc(createCollection<IFile>(COLLECTION_FILE), id), {
        parent_id: move.id,
        category_id: move.category_id,
      })

      const recursiveMove = async (id: string) => {
        let q = query(
          createCollection<IFile>(COLLECTION_FILE),
          where('parentId', '==', id)
        )

        const querySnapshot = await getDocs(q)

        querySnapshot.docs.forEach((item) => {
          updateDoc(doc(createCollection<IFile>(COLLECTION_FILE), item.id), {
            category_id: move.category_id,
          })

          if (querySnapshot.docs.length) {
            recursiveMove(item.id)
          }
        })
      }

      await recursiveMove(id)

      dispatch(removeFile(id))
    } catch (error) {
      console.log(error)
    }
  }

export const deleteFile = (id: string) => async (dispatch: AppDispatchType) => {
  try {
    // const { data, error } = await supabase
    //   .storage
    //   .from('avatars')
    //   .remove(['folder/avatar1.png'])

    const response = await supabase.from(TABLE_NAME).delete().eq('id', id)

    dispatch(removeFile(id))
  } catch (error) {
    console.log(error)
  }
}

export const searchFiles =
  (title: string, user_id: string) => async (dispatch: AppDispatchType) => {
    try {
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .select()
        .textSearch('title', title)

      if (error) return console.log(error)

      return data
    } catch (error) {
      console.log(error)
    }
  }
