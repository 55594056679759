import React, { FC, ReactNode, CSSProperties } from 'react'

type PropsType = {
  children: ReactNode
  style?: CSSProperties
}

const Container: FC<PropsType> = ({ children, style }) => {
  return <div style={{ padding: '0 20px', ...style }}>{children}</div>
}

export default Container
