import React, { FC } from 'react'
import { Badge, Button, Popup } from 'antd-mobile-v5'
import { Container, Title, Text } from '../../UI'
import img from '../../../assets/img/paywall-save-document.svg'

interface PropsType {
  count: number
  isVisible: boolean
  onSuccess: () => void
  onClose: () => void
}

const PaywallSaveDocument: FC<PropsType> = ({ count, isVisible, onClose }) => {
  return (
    <Popup
      visible={isVisible}
      bodyStyle={{
        maxHeight: '90vh',
        textAlign: 'center',
        overflowY: 'scroll',
      }}
      showCloseButton
      onClose={onClose}
      onMaskClick={onClose}
    >
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '100%',
        }}
      >
        <div>
          <img src={img} alt="Save the document" />
        </div>

        <div style={{ margin: '40px 0' }}>
          <Title level={1} tag="div" style={{ marginBottom: 15 }}>
            Save the document
          </Title>

          <Text size="lg">
            You can save a document up to 5 pages for free. Do you want to save
            this <strong>{count} pages</strong> document?
          </Text>
        </div>

        <div style={{ width: '100%' }}>
          <Badge
            content="25% Off"
            bordered
            wrapperStyle={{ display: 'block', width: '100%' }}
          >
            <Button color="primary" size="large" block>
              Save with trial
            </Button>
          </Badge>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              margin: '35px -20px 0',
            }}
          >
            <Button
              color="default"
              fill="none"
              size="small"
              style={{ color: 'var(--adm-color-text-secondary)' }}
            >
              Privacy Policy
            </Button>

            <Button
              color="default"
              fill="none"
              size="small"
              style={{ color: 'var(--adm-color-text-secondary)' }}
            >
              Terms of Use
            </Button>

            <Button
              color="default"
              fill="none"
              size="small"
              style={{ color: 'var(--adm-color-text-secondary)' }}
            >
              Restore Purchase
            </Button>
          </div>
        </div>
      </Container>
    </Popup>
  )
}

export default PaywallSaveDocument
