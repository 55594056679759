import React, { FC, CSSProperties, createElement } from 'react'
import './Title.scss'

type PropsType = {
  level: number
  children: React.ReactNode
  tag?: string
  bold?: boolean
  style?: CSSProperties
}

const Title: FC<PropsType> = ({ level, children, tag, bold, style }) => {
  const CustomTag = tag || `h${level}`
  const classBold = bold ? 'title-bold' : ''
  const classNames = [`title title-${level}`, classBold].join(' ')

  return createElement(
    CustomTag,
    {
      className: classNames,
      style,
    },
    children
  )
}

export default Title
