import React, { FC, useState } from 'react'
import { Button, Popup } from 'antd-mobile-v5'
import { Container, Title, Text } from '../../UI'
import ChoicePlan from '../../ChoicePlan/ChoicePlan'
import { setPaywall } from '../../../store/reducers/paywall'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getProfile, updateProfile } from '../../../store/actions/profile'
import { useAuth } from '../../../hooks/useAuth'
import PaywallPremium from '../PaywallPremium/PaywallPremium'
import PaywallPremiumUpdate from '../PaywallPremiumUpdate/PaywallPremiumUpdate'
import dayjs from 'dayjs'

interface PropsType {
  isVisible: boolean
  onSuccess: () => void
  onClose: () => void
}

const choices = [
  {
    value: 'FULL',
    title: 'Get full premium access',
    caption: 'Try free for 3 days, then $9.99/week',
  },
  {
    value: 'PACKAGE',
    title: '+ 100 docs + 20 folders',
    caption: 'Buy document package for $4.99',
  },
]

const PaywallUnlimitedAccess: FC<PropsType> = ({
  isVisible,
  onSuccess,
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const { user } = useAuth()
  const profile = useAppSelector((state) => state.profile)
  const [isPaywallPremium, setIsPaywallPremium] = useState<boolean>(false)
  const [isPaywallPremiumUpdate, setIsPaywallPremiumUpdate] =
    useState<boolean>(false)
  const [choice, setChoice] = useState<string>('FULL')

  const handleClickButton = async () => {
    if (choice === 'FULL') {
      if (dayjs(profile.active_till).diff(dayjs(), 'minute') < 0) {
        setIsPaywallPremiumUpdate(true)
      } else {
        setIsPaywallPremium(true)
      }
    }

    if (choice === 'PACKAGE') {
      await dispatch(
        updateProfile(user.id, {
          ...profile,
          folders: profile.folders + 20,
          files: profile.files + 100,
        })
      )

      onSuccess()
    }
  }

  const handlePremiumSuccess = async () => {
    setIsPaywallPremium(false)
    setIsPaywallPremiumUpdate(false)
    onSuccess()
  }

  return (
    <>
      <Popup
        visible={isVisible}
        bodyStyle={{
          paddingTop: 50,
          maxHeight: '90vh',
          textAlign: 'center',
          overflowY: 'scroll',
          backgroundColor: '#CCEEFF',
        }}
        showCloseButton
        onMaskClick={onClose}
        onClose={onClose}
      >
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: '100%',
          }}
        >
          <div style={{ margin: '0 0 40px' }}>
            <Title level={1} tag="div" style={{ marginBottom: 15 }}>
              Unlimited access
            </Title>

            <Text size="lg">
              Get access to unlimited pages & folders, advanced visual edition
              and document sign in
            </Text>
          </div>

          <ChoicePlan
            theme="secondary"
            choices={choices}
            value={choice}
            onChange={(value: string) => setChoice(value)}
          />

          <div style={{ marginTop: 25, width: '100%' }}>
            <Button
              color="primary"
              size="large"
              block
              onClick={handleClickButton}
            >
              Continue
            </Button>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                margin: '35px -20px 0',
              }}
            >
              <Button
                color="default"
                fill="none"
                size="small"
                style={{ color: 'var(--adm-color-text-secondary)' }}
              >
                Privacy Policy
              </Button>

              <Button
                color="default"
                fill="none"
                size="small"
                style={{ color: 'var(--adm-color-text-secondary)' }}
              >
                Terms of Use
              </Button>

              <Button
                color="default"
                fill="none"
                size="small"
                style={{ color: 'var(--adm-color-text-secondary)' }}
              >
                Restore Purchase
              </Button>
            </div>
          </div>
        </Container>
      </Popup>

      <PaywallPremium
        isVisible={isPaywallPremium}
        onSuccess={handlePremiumSuccess}
        onClose={() => setIsPaywallPremium(false)}
      />

      <PaywallPremiumUpdate
        isVisible={isPaywallPremiumUpdate}
        onSuccess={handlePremiumSuccess}
        onClose={() => setIsPaywallPremiumUpdate(false)}
      />
    </>
  )
}

export default PaywallUnlimitedAccess
