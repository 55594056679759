import React, { FC, useRef, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'antd-mobile-v5'
import ProfileMenu from '../ProfileMenu/ProfileMenu'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import SvgIcon from '../SvgIcon/SvgIcon'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setSortPage, setViewPage } from '../../store/reducers/page'
import styles from './Navbar.module.scss'
import dayjs from 'dayjs'

type PropsType = {}

const Navbar: FC<PropsType> = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const navbarEl = useRef<HTMLDivElement>(null)
  const profile = useAppSelector((state) => state.profile)
  const { color, sort, view } = useAppSelector((state) => state.page)
  const [stickyNavbar, setStickyNavbar] = useState<boolean>(false)
  const [boxShadow, setBoxShadow] = useState<string>('none')
  const [backgroundColor, setBackgroundColor] = useState<string>('transparent')
  const [profileMenuVisible, setProfileMenuVisible] = useState<boolean>(false)

  const isPremium = dayjs(profile.active_till).diff(dayjs(), 'minute') > 0

  const sortIconName = `sort-${!sort || sort === 'desc' ? 'desc' : 'asc'}`
  const viewIconName = `view-${view === 'grid' ? 'list' : 'grid'}`

  const handleClickSearch = () => {
    navigate('/search')
  }

  const handleClickSort = () => {
    const sortPayload = !sort || sort === 'desc' ? 'asc' : 'desc'
    dispatch(setSortPage(sortPayload))
  }

  const handleClickView = () => {
    const viewPayload = view === 'grid' ? 'list' : 'grid'
    dispatch(setViewPage(viewPayload))
  }

  // todo
  const handleClickBack = () => {
    navigate(-1)
  }

  const isSticky = () => {
    const scrollTop = window.scrollY

    if (navbarEl && navbarEl.current) {
      if (scrollTop > 0) {
        setStickyNavbar(true)
        setBoxShadow('0px 1px 3px 0px rgba(0, 0, 0, 0.15)')
        setBackgroundColor(color)
      } else {
        setStickyNavbar(false)
        setBoxShadow('none')
        setBackgroundColor('transparent')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', isSticky)

    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })

  return (
    <div
      ref={navbarEl}
      className={styles.navbar}
      style={{
        position: stickyNavbar ? 'sticky' : 'relative',
        backgroundColor: stickyNavbar ? '#fff' : 'transparent',
        boxShadow,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          backgroundColor,
        }}
      />
      {pathname === '/dashboard' ? (
        <>
          <ButtonIcon onClick={() => setProfileMenuVisible(true)}>
            <SvgIcon name="burger" />
          </ButtonIcon>

          {isPremium && (
            <SvgIcon
              name="premium"
              style={{ color: '#02AAFF', marginLeft: 10 }}
            />
          )}
        </>
      ) : (
        <Button
          fill="none"
          onClick={handleClickBack}
          style={{ marginLeft: -5 }}
        >
          <SvgIcon name="angle-left" width={14} />
          Back
        </Button>
      )}

      <div className={styles.aside}>
        <ButtonIcon onClick={handleClickSearch}>
          <SvgIcon name="search" />
        </ButtonIcon>

        <ButtonIcon style={{ marginLeft: 8 }} onClick={handleClickSort}>
          <SvgIcon name={sortIconName} />
        </ButtonIcon>

        <ButtonIcon style={{ marginLeft: 8 }} onClick={handleClickView}>
          <SvgIcon name={viewIconName} />
        </ButtonIcon>
      </div>

      <ProfileMenu
        isVisible={profileMenuVisible}
        onClose={() => setProfileMenuVisible(false)}
      />
    </div>
  )
}

export default Navbar
