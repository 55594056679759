import React, { FC } from 'react'
import SvgIcon from '../SvgIcon/SvgIcon'
import Title from '../UI/Title/Title'
import styles from './AddCardButton.module.scss'
import { setPaywall } from '../../store/reducers/paywall'
import { useAppDispatch } from '../../hooks'

type PropsType = {
  title: string
  folder?: boolean
  isGrid?: boolean
  onClick: Function
}

const AddCardButton: FC<PropsType> = ({
  title,
  folder = false,
  isGrid,
  onClick,
}) => {
  const dispatch = useAppDispatch()

  const sizeIcon = folder && !isGrid ? 8 : 22

  const rootClassName = [
    styles.root,
    isGrid && folder ? styles.rootFolderGrid : '',
    !isGrid && folder ? styles.rootFolderList : '',
    isGrid && !folder ? styles.rootCategoryGrid : '',
    !isGrid && !folder ? styles.rootCategoryList : '',
  ]
    .join(' ')
    .trim()

  const handleClick = () => {
    // dispatch(
    //   setPaywall({
    //     name: 'PaywallUnlimitedAccess',
    //   })
    // )

    onClick()
  }

  return (
    <div className={rootClassName} onClick={handleClick}>
      {!folder && <SvgIcon name="plus" width={sizeIcon} height={sizeIcon} />}

      {!folder && (
        <Title
          level={4}
          tag="div"
          bold
          style={{
            marginTop: isGrid ? 15 : 0,
            marginLeft: isGrid ? 0 : 45,
          }}
        >
          {title}
        </Title>
      )}

      {folder && (
        <Title
          level={6}
          tag="div"
          style={{ marginTop: folder && !isGrid ? 0 : 15 }}
        >
          {title}
        </Title>
      )}
    </div>
  )
}

export default AddCardButton
