import { SortType } from '../types'

export const sortBy = (data: Array<any>, key: string, sort: SortType) => {
  return [...data].sort((a, b) => {
    const prev: string = a[key].toLowerCase()
    const next: string = b[key].toLowerCase()

    if (sort === 'asc') {
      if (prev < next) {
        return -1
      }
      if (prev > next) {
        return 1
      }
    }

    if (sort === 'desc') {
      if (prev < next) {
        return 1
      }
      if (prev > next) {
        return -1
      }
    }

    return 0
  })
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',')
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}
