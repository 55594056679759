import { IFile } from '../../types'

const SET_FILE_LIST = 'SET_FILE_LIST'
const ADD_FILE = 'ADD_FILE'
const UPDATE_FILE = 'UPDATE_FILE'
const REMOVE_FILE = 'REMOVE_FILE'

const initialState = {
  fileList: [] as IFile[],
}

type InitialState = typeof initialState

export const fileReducer = (
  state = initialState,
  action: any
): InitialState => {
  switch (action.type) {
    case SET_FILE_LIST:
      return {
        ...state,
        fileList: action.payload,
      }

    case ADD_FILE:
      return {
        ...state,
        fileList: [...state.fileList, action.payload],
      }

    case UPDATE_FILE:
      return {
        ...state,
        fileList: state.fileList.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            }
          }

          return item
        }),
      }

    case REMOVE_FILE:
      return {
        ...state,
        fileList: state.fileList.filter(({ id }) => id !== action.payload),
      }
    default:
      return state
  }
}

type SetFileListActionType = {
  type: typeof SET_FILE_LIST
  payload: IFile[]
}

export const setFileList = (payload: IFile[]): SetFileListActionType => ({
  type: SET_FILE_LIST,
  payload,
})

type AddFileActionType = {
  type: typeof ADD_FILE
  payload: IFile
}

export const addFile = (payload: IFile): AddFileActionType => ({
  type: ADD_FILE,
  payload,
})

type UpdateFileActionPayloadType = {
  id: string
  title: string
}

type UpdateFileActionType = {
  type: typeof UPDATE_FILE
  payload: UpdateFileActionPayloadType
}

export const updateFile = (
  payload: UpdateFileActionPayloadType
): UpdateFileActionType => ({
  type: UPDATE_FILE,
  payload,
})

type RemoveFileActionType = {
  type: typeof REMOVE_FILE
  payload: string
}
export const removeFile = (id: string): RemoveFileActionType => ({
  type: REMOVE_FILE,
  payload: id,
})
