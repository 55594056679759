import { IFolder } from '../../types'

const SET_FOLDER_LIST = 'SET_FOLDER_LIST'
const ADD_FOLDER = 'ADD_FOLDER'
const UPDATE_FOLDER = 'UPDATE_FOLDER'
const REMOVE_FOLDER = 'REMOVE_FOLDER'

const initialState = {
  folderList: [] as IFolder[],
}

type InitialState = typeof initialState

export const folderReducer = (
  state = initialState,
  action: any
): InitialState => {
  switch (action.type) {
    case SET_FOLDER_LIST:
      return {
        ...state,
        folderList: action.payload,
      }

    case ADD_FOLDER:
      return {
        ...state,
        folderList: [...state.folderList, action.payload],
      }

    case UPDATE_FOLDER:
      return {
        ...state,
        folderList: state.folderList.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            }
          }

          return item
        }),
      }

    case REMOVE_FOLDER:
      return {
        ...state,
        folderList: state.folderList.filter(({ id }) => id !== action.payload),
      }
    default:
      return state
  }
}

type SetFolderListActionType = {
  type: typeof SET_FOLDER_LIST
  payload: IFolder[]
}

export const setFolderList = (payload: IFolder[]): SetFolderListActionType => ({
  type: SET_FOLDER_LIST,
  payload,
})

type AddFolderActionType = {
  type: typeof ADD_FOLDER
  payload: IFolder
}

export const addFolder = (payload: IFolder): AddFolderActionType => ({
  type: ADD_FOLDER,
  payload,
})

type UpdateFolderActionPayloadType = {
  id: string
  title: string
}

type UpdateFolderActionType = {
  type: typeof UPDATE_FOLDER
  payload: UpdateFolderActionPayloadType
}

export const updateFolder = (
  payload: UpdateFolderActionPayloadType
): UpdateFolderActionType => ({
  type: UPDATE_FOLDER,
  payload,
})

type RemoveFolderActionType = {
  type: typeof REMOVE_FOLDER
  payload: string
}
export const removeFolder = (id: string): RemoveFolderActionType => ({
  type: REMOVE_FOLDER,
  payload: id,
})
