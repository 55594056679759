import { ICategory } from '../../types'

const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST'
const SORT_CATEGORY_LIST = 'SORT_CATEGORY_LIST'
const ADD_CATEGORY = 'ADD_CATEGORY'
const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
const REMOVE_CATEGORY = 'REMOVE_CATEGORY'

type ActionType =
  | typeof SET_CATEGORY_LIST
  | typeof SORT_CATEGORY_LIST
  | typeof ADD_CATEGORY
  | typeof UPDATE_CATEGORY
  | typeof REMOVE_CATEGORY

type InitialStateType = {
  categoryList: ICategory[]
}

const initialState: InitialStateType = {
  categoryList: [],
}

export const categoryReducer = (
  state = initialState,
  action: {
    type: ActionType
    payload?: any
  }
): InitialStateType => {
  switch (action.type) {
    case SET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      }

    case ADD_CATEGORY:
      return {
        ...state,
        categoryList: [...state.categoryList, action.payload],
      }

    case UPDATE_CATEGORY:
      return {
        ...state,
        categoryList: state.categoryList.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            }
          }

          return item
        }),
      }

    case REMOVE_CATEGORY:
      return {
        ...state,
        categoryList: state.categoryList.filter(
          ({ id }) => id !== action.payload
        ),
      }

    default:
      return state
  }
}

type SetCategoryListActionType = {
  type: typeof SET_CATEGORY_LIST
  payload: ICategory[]
}

export const setCategoryList = (
  payload: ICategory[]
): SetCategoryListActionType => ({
  type: SET_CATEGORY_LIST,
  payload,
})

type AddCategoryActionType = {
  type: typeof ADD_CATEGORY
  payload: ICategory
}

export const addCategory = (payload: ICategory): AddCategoryActionType => ({
  type: ADD_CATEGORY,
  payload,
})

type UpdateCategoryActionPayloadType = {
  id: string
  title: string
}

type UpdateCategoryActionType = {
  type: typeof UPDATE_CATEGORY
  payload: UpdateCategoryActionPayloadType
}

export const updateCategory = (
  payload: UpdateCategoryActionPayloadType
): UpdateCategoryActionType => ({
  type: UPDATE_CATEGORY,
  payload,
})

type RemoveCategoryActionType = {
  type: typeof REMOVE_CATEGORY
  payload: string
}
export const removeCategory = (id: string): RemoveCategoryActionType => ({
  type: REMOVE_CATEGORY,
  payload: id,
})
