import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { IFile } from '../../types'
import { useAppDispatch } from '../../hooks'
import SvgIcon from '../SvgIcon/SvgIcon'
import Preview from '../Preview/Preview'
import { Text, Title } from '../UI'
import './FileCard.scss'
import ModalFormRename from '../Modal/ModalFormRename/ModalFormRename'
import ModalDelete from '../Modal/ModalDelete/ModalDelete'
import { deleteFile, renameFile } from '../../store/actions/file'
import { ActionSheet } from 'antd-mobile-v5'
import ActionSheetButton from '../ActionSheetButton/ActionSheetButton'

interface PropsType {
  data: IFile
  color?: string
  controls?: boolean
  isGrid?: boolean
  onMove?: Function
}

const FileCard: FC<PropsType> = ({ data, controls, isGrid, onMove }) => {
  const dispatch = useAppDispatch()
  const [isContextMenu, setIsContextMenu] = useState<boolean>(false)
  const [isModalRename, setIsModalRename] = useState<boolean>(false)
  const [isModalDelete, setIsModalDelete] = useState<boolean>(false)

  const contextMenuActions = [
    {
      text: <ActionSheetButton icon="rename" title="Rename" />,
      key: 'rename',
    },
    {
      text: <ActionSheetButton icon="move" title="Move" />,
      key: 'move',
    },
    {
      text: <ActionSheetButton icon="share" title="Share" />,
      key: 'share',
    },
    {
      text: <ActionSheetButton icon="edit" title="Edit" />,
      key: 'edit',
    },
    {
      text: <ActionSheetButton icon="delete" title="Delete" />,
      key: 'delete',
      danger: true,
    },
  ]

  const handleRename = async (value: string) => {
    await dispatch(renameFile(data.id, value))
  }

  const handleDelete = async () => {
    await dispatch(deleteFile(data.id))
  }

  const handleMenuAction = (key: string | number) => {
    switch (key) {
      case 'rename':
        setIsModalRename(true)
        setIsContextMenu(false)
        break

      case 'move':
        if (onMove) {
          onMove(data.id, data.parent_id)
        }
        setIsContextMenu(false)
        break

      case 'delete':
        setIsModalDelete(true)
        setIsContextMenu(false)
        break
    }
  }

  return (
    <>
      <div className={`folder-card ${isGrid ? 'is-grid' : 'is-list'}`}>
        <Link to={`/file/${data.id}`} className="folder-card__link">
          <Preview
            url={data.url}
            width={isGrid ? 80 : 32}
            height={isGrid ? 70 : 28}
          />

          <Text size="lg">
            {data.title}.{data.extension}
          </Text>
        </Link>

        {controls && (
          <span
            className="folder-card__more"
            onClick={() => setIsContextMenu(true)}
          >
            <SvgIcon name="dots-v" />
          </span>
        )}
      </div>

      <ActionSheet
        visible={isContextMenu}
        onMaskClick={() => setIsContextMenu(false)}
        onAction={({ key }) => handleMenuAction(key)}
        extra={
          <>
            <Preview url={data.url} width={24} height={21} />

            <Title level={4} bold>
              {data.title}
            </Title>
          </>
        }
        actions={contextMenuActions}
      />

      <ModalFormRename
        visible={isModalRename}
        title="Rename file"
        text="Enter a name for your file"
        placeholder="File Name"
        value={data.title}
        onSubmit={handleRename}
        onClose={() => setIsModalRename(false)}
      />

      <ModalDelete
        visible={isModalDelete}
        heading="Delete file?"
        url={data.url}
        title={data.title}
        onSubmit={handleDelete}
        onClose={() => setIsModalDelete(false)}
      />
    </>
  )
}

export default FileCard
