import { SortType, ViewType } from '../../types'
import colorAlpha from 'color-alpha'

const SET_PAGE = 'SET_PAGE'
const SET_LOADING_PAGE = 'SET_LOADING_PAGE'
const SET_VIEW_PAGE = 'SET_VIEW_PAGE'
const SET_SORT_PAGE = 'SET_SORT_PAGE'

type PageType = {
  color: string
  iconColor?: string
  title: string
  id: string
  loading?: boolean
  view?: ViewType
  sort?: SortType
}

type InitialStateType = PageType

const initialState: InitialStateType = {
  color: '#fff',
  iconColor: '#02aaff',
  title: '',
  id: '',
  loading: false,
  view: 'grid',
  sort: null,
}

export const pageReducer = (
  state: InitialStateType = initialState,
  action: any
): InitialStateType => {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state,
        ...action.payload,
      }

    case SET_LOADING_PAGE:
      return {
        ...state,
        loading: action.payload,
      }

    case SET_VIEW_PAGE:
      return {
        ...state,
        view: action.payload,
      }

    case SET_SORT_PAGE:
      return {
        ...state,
        sort: action.payload,
      }
    default:
      return state
  }
}

type SetPageActionType = {
  type: typeof SET_PAGE
  payload: PageType
}

export const setPage = (payload: PageType): SetPageActionType => ({
  type: SET_PAGE,
  payload: {
    ...payload,
    color: colorAlpha(payload.color, 0.21),
    iconColor: payload.iconColor || payload.color,
  },
})

type SetPageLoadingActionType = {
  type: typeof SET_LOADING_PAGE
  payload: boolean
}

// export const setLoadingPage = (payload: boolean): SetPageLoadingActionType => ({
//   type: SET_LOADING_PAGE,
//   payload,
// })

type SetPageSortActionType = {
  type: typeof SET_SORT_PAGE
  payload: SortType
}

export const setSortPage = (payload: SortType): SetPageSortActionType => ({
  type: SET_SORT_PAGE,
  payload,
})

type SetPageViewActionType = {
  type: typeof SET_VIEW_PAGE
  payload: ViewType
}

export const setViewPage = (payload: ViewType): SetPageViewActionType => ({
  type: SET_VIEW_PAGE,
  payload,
})
