const SET_PAYWALL = 'SET_PAYWALL'

type PaywallType =
  | 'PaywallPremium'
  | 'PaywallPremiumUpdate'
  | 'PaywallSaveDocument'
  | 'PaywallUnlimitedAccess'
  | null

type InitialStateType = PaywallType

const initialState: InitialStateType = null

export const paywallReducer = (
  state: InitialStateType = initialState,
  action: any
): InitialStateType => {
  switch (action.type) {
    case SET_PAYWALL:
      return action.payload
    default:
      return state
  }
}

type SetPaywallActionType = {
  type: typeof SET_PAYWALL
  payload: PaywallType
}

export const setPaywall = (payload: PaywallType): SetPaywallActionType => ({
  type: SET_PAYWALL,
  payload,
})
