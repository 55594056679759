import React, { FC, CSSProperties } from 'react'
import { Button } from 'antd-mobile-v5'

type PropsType = {
  children: React.ReactNode
  color?: string
  width?: number
  height?: number
  style?: CSSProperties
  onClick: () => void
}

const ButtonIcon: FC<PropsType> = ({
  width = 40,
  height = 40,
  color = 'transparent',
  style,
  children,
  onClick,
}) => {
  return (
    <>
      <Button
        fill="none"
        shape="rounded"
        style={{
          padding: 5,
          width: width,
          height: height,
          backgroundColor: color,
          ...style,
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  )
}

export default ButtonIcon
