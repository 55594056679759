import React, { useState } from 'react'
import FolderCard from '../components/FolderCard/FolderCard'
import { useAppDispatch, useAppSelector } from '../hooks'
import { IFolder, IFile } from '../types'
import { searchFolders } from '../store/actions/folder'
import { searchFiles } from '../store/actions/file'
import SearchForm from '../components/SearchForm/SearchForm'
import { Space } from 'antd-mobile-v5'
import FileCard from '../components/FileCard/FileCard'
import Title from '../components/UI/Title/Title'
import { useAuth } from '../hooks/useAuth'

function Search() {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const [query, setQuery] = useState<string>('')
  const [folderList, setFolderList] = useState<IFolder[]>([])
  const [fileList, setFileList] = useState<IFile[]>([])

  const changeHandler = async (value: string) => {
    setQuery(value)

    if (value && user) {
      const [folders, files] = await Promise.all([
        (await dispatch(searchFolders(value))) as IFolder[],
        (await dispatch(searchFiles(value, user.id))) as IFile[],
      ])

      setFolderList(folders)
      setFileList(files)
    } else {
      setFolderList([])
      setFileList([])
    }
  }

  return (
    <div style={{ padding: '15px 20px' }}>
      <div style={{ marginBottom: 20, marginRight: -16 }}>
        <SearchForm onChange={changeHandler} />
      </div>

      {query && user && (
        <div className="search__results">
          <Title tag="div" level={5} bold style={{ marginBottom: 20 }}>
            Results
          </Title>

          <div className="search__scrollbar">
            <Space direction="vertical" block>
              {folderList.map((item) => (
                <FolderCard key={item.id} data={item} />
              ))}

              {fileList.map((item) => (
                <FileCard key={item.id} data={item} />
              ))}
            </Space>
          </div>
        </div>
      )}
    </div>
  )
}

export default Search
