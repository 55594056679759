import React, { FC, useState } from 'react'
import { Button, Popup } from 'antd-mobile-v5'
import PopupMenu from '../PopupMenu/PopupMenu'
import ProVersionNotify from '../ProVersionNotify/ProVersionNotify'
import Title from '../UI/Title/Title'
import SvgIcon from '../SvgIcon/SvgIcon'
import { useAuth } from '../../hooks/useAuth'
import { setCategoryList } from '../../store/reducers/category'

type PropsType = {
  isVisible: boolean
  onClose: Function
}

const actions = [
  { title: 'Share App', key: 'share', icon: 'share', more: true },
  { title: 'Restore Puchase', key: 'restore', icon: 'restore', more: true },
  { title: 'Privacy Policy', key: 'privacy', icon: 'privacy', more: true },
  { title: 'Term of Use', key: 'term', icon: 'file', more: true },
  { title: 'Contact Us', key: 'contact', icon: 'message', more: true },
]

const ProfileMenu: FC<PropsType> = ({ isVisible, onClose }) => {
  const { signout } = useAuth()
  const [childrenMenuTitle, setChildrenMenuTitle] = useState<string>('')

  const handleMenuAction = (key: string, title: string) => {
    setChildrenMenuTitle(title)
  }

  const handleClose = () => {
    setChildrenMenuTitle('')
    onClose()
  }

  const handleLogout = () => {
    setCategoryList([])
    signout()
  }

  return (
    <Popup
      visible={isVisible}
      bodyStyle={{ maxHeight: '90vh', overflowY: 'scroll' }}
      onMaskClick={() => handleClose()}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 10px',
          marginBottom: 15,
        }}
      >
        <div style={{ flex: '0 0 20%' }}>
          {!childrenMenuTitle ? (
            <Button color="default" fill="none" onClick={handleLogout}>
              Logout
            </Button>
          ) : (
            <Button fill="none" onClick={() => setChildrenMenuTitle('')}>
              <SvgIcon name="angle-left" width={14} />
              Back
            </Button>
          )}
        </div>

        <div style={{ flex: '1 1 auto', textAlign: 'center' }}>
          <Title level={5} tag="div" bold>
            {childrenMenuTitle || 'My Account'}
          </Title>
        </div>

        <div style={{ flex: '0 0 20%', textAlign: 'right' }}>
          {!childrenMenuTitle && (
            <Button color="primary" fill="none" onClick={() => handleClose()}>
              Done
            </Button>
          )}
        </div>
      </div>

      {!childrenMenuTitle ? (
        <>
          <div style={{ marginBottom: 25, padding: '0 20px' }}>
            <ProVersionNotify />
          </div>

          <PopupMenu actions={actions} onAction={handleMenuAction} />
        </>
      ) : (
        <div style={{ padding: '0 20px', lineHeight: 1.6 }}>
          <p>
            This Privacy Policy covers the information we collect about you when
            you use our products or services, or otherwise interact with us (for
            example, by attending our premises or events or by communicating
            with us), unless a different policy is displayed. We offer a wide
            range of products, including our cloud, server and data center
            products. We refer to all of these products, together with our other
            services and websites as "Services" in this policy.
          </p>
          <p>
            This policy also explains your choices surrounding how we use
            information about you, which include how you can object to certain
            uses of information about you and how you can access and update
            certain information about you. If you do not agree with this policy,
            do not access or use our Services or interact with any other aspect
            of our business.
          </p>
          <p>
            Where we provide the Services under contract with an organization
            (for example, your employer) that organization controls the
            information processed by the Services. For more information, please
            see Notice to End Users below. This policy does not apply to the
            extent we process personal information in the role of a processor on
            behalf of such organizations.
          </p>
        </div>
      )}
    </Popup>
  )
}

export default ProfileMenu
