import React, { FC, useState, useEffect } from 'react'
import { ICategory } from '../../types'
import Title from '../UI/Title/Title'
import SvgIcon from '../SvgIcon/SvgIcon'
import { Button } from 'antd-mobile-v5'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import { Link, useNavigate } from 'react-router-dom'
import colorAlpha from 'color-alpha'

type PropsType = {
  list: ICategory[]
  onSelect?: Function
}

const ChooseCategory: FC<PropsType> = ({ list }) => {
  const navigate = useNavigate()
  const [filteredList, setFilteredList] = useState<ICategory[]>([])

  useEffect(() => {
    const filtered = list.filter((item) => item.required)
    setFilteredList(filtered)
  }, [list])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto',
        padding: '30px 0 120px',
        minHeight: '100vh',
        width: 235,
        textAlign: 'center',
      }}
    >
      <Title level={4} bold style={{ marginBottom: 30 }}>
        Choose category
      </Title>

      <div>
        {filteredList.map((item) => (
          <Link
            to={`/scan?category_id=${item.id}`}
            key={item.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              zIndex: 1,
              marginBottom: 15,
              height: 56,
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            <Title level={4} bold>
              {item.title}
            </Title>

            <div style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}>
              <svg
                width="235"
                height="56"
                viewBox="0 0 235 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M234.067 4.93143C233.217 3.74968 231.717 2.61705 231.601 1.39859C224.872 1.18283 218.647 1.12519 211.855 1.12965C204.758 1.12965 179.625 2.25191 172.79 1.71603C123.298 1.27571 80.2588 1.0664 58.5603 1.24835C18.4151 1.5974 40.1754 3.25198 0 0C1.78346 3.88154 5.61215 6.43941 0 10.2746C0.565913 12.0888 3.15898 17.1365 3.73764 18.9593C5.49339 24.517 2.05356 24.7991 0.218348 30.3305C0.218348 34.6135 5.14062 35.7667 0.902899 42.6229C0.716462 46.8638 1.91505 51.1478 3.08441 55.3743C40.322 54.0395 50.843 57.0698 83.6184 55.5741C102.689 54.7039 125.647 55.5979 132.529 55.9214C139.333 55.4814 152.083 55.1597 158.676 55.9868C166.267 55.7124 185.256 55.0015 192.909 55.0512C209.999 55.1535 215.406 55.1756 232.503 55.5473C233.912 50.8449 226.922 46.8418 231.079 41.3042C233.798 37.6835 227.398 32.5951 231.079 28.3123C236.022 22.5618 227.902 21.2927 231.427 15.1613C233.368 11.8193 236.605 8.32178 234.067 4.93143Z"
                  fill={colorAlpha(item.color, 0.21)}
                />
              </svg>
            </div>
          </Link>
        ))}
      </div>

      {list.length > filteredList.length && (
        <Button
          fill="none"
          style={{ color: 'rgba(32, 38, 62, 0.3)', fontWeight: 500 }}
          onClick={() => setFilteredList(list)}
        >
          <SvgIcon name="plus" width={16} />
          Other
        </Button>
      )}

      <div
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 35,
          zIndex: 5,
          textAlign: 'center',
        }}
      >
        <ButtonIcon
          color="#02AAFF"
          width={56}
          height={56}
          onClick={() => navigate('/dashboard')}
        >
          <SvgIcon width={32} height={32} name="close" />
        </ButtonIcon>
      </div>
    </div>
  )
}

export default ChooseCategory
