import React, { FC, useRef } from 'react'
import { Form, Input, Modal } from 'antd-mobile-v5'
import { Text, Title } from '../../UI'
import { FormInstance } from 'antd-mobile-v5/es/components/form'
import { InputRef } from 'antd-mobile-v5/es/components/input'
import Preview from '../../Preview/Preview'

type PropsType = {
  visible: boolean
  title?: string
  text?: string
  placeholder: string
  onSubmit: Function
  onClose: Function
}

const ModalFormCreate: FC<PropsType> = ({
  visible,
  title,
  text,
  placeholder,
  onSubmit,
  onClose,
}) => {
  const refForm = useRef<FormInstance>(null)
  const refInput = useRef<InputRef>(null)

  const handleSubmit = async () => {
    const value = refForm?.current?.getFieldValue('title')?.trim()

    if (!value) {
      refInput?.current?.focus()
      return
    }

    await onSubmit(value)
    await onClose()

    refForm?.current?.resetFields()
  }

  return (
    <Modal
      visible={visible}
      header={!title && <Preview placeholder="?" />}
      title={
        title && (
          <Title tag="div" level={4} bold>
            {title}
          </Title>
        )
      }
      content={
        <>
          {text && <Text>{text}</Text>}

          <Form
            ref={refForm}
            style={{ marginTop: 20 }}
            onFinish={() => handleSubmit()}
          >
            <Form.Item
              name="title"
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input placeholder={placeholder} ref={refInput} />
            </Form.Item>
          </Form>
        </>
      }
      onClose={() => onClose()}
      afterShow={() => refInput?.current?.focus()}
      actions={[
        {
          key: 'save',
          text: 'Save',
          primary: true,
          onClick: () => handleSubmit(),
        },
        {
          key: 'cancel',
          text: 'Cancel',
          onClick: () => onClose(),
        },
      ]}
    />
  )
}

export default ModalFormCreate
