import { AppDispatchType } from '../index'

const SET_PROFILE = 'SET_PROFILE'
const CHANGE_PROFILE = 'CHANGE_PROFILE'

export type ProfileType = {
  active_till: string | null
  files: number
  folders: number
  id: string | null
  subscribe: string | null
}

type InitialStateType = ProfileType

const initialState: InitialStateType = {
  active_till: null,
  files: 0,
  folders: 0,
  id: null,
  subscribe: null,
}

export const profileReducer = (
  state: InitialStateType = initialState,
  action: any
): InitialStateType => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        ...action.payload,
      }
    case CHANGE_PROFILE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

type SetUserActionType = {
  type: typeof SET_PROFILE
  payload: ProfileType
}

export const setProfile = (payload: ProfileType): SetUserActionType => ({
  type: SET_PROFILE,
  payload,
})

type ChangeUserActionType = {
  type: typeof CHANGE_PROFILE
  payload: ProfileType
}

export const changeProfile = (payload: ProfileType): ChangeUserActionType => ({
  type: CHANGE_PROFILE,
  payload,
})
