import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { getCategoryList } from '../store/actions/category'
import { useQuery } from '../hooks'
import ChooseCategory from '../components/ChooseCategory/ChooseCategory'
import CreateFile from '../components/CreateFile/CreateFile'
import { useAuth } from '../hooks/useAuth'
import PaywallSaveDocument from '../components/Paywall/PaywallSaveDocument/PaywallSaveDocument'

const Scan = () => {
  const { user } = useAuth()
  const dispatch = useAppDispatch()

  const query = useQuery()
  const paywall = useAppSelector((state) => state.paywall)
  const { categoryList } = useAppSelector((state) => state.category)

  useEffect(() => {
    if (user) {
      dispatch(getCategoryList())
    }
  }, [user])

  if (!query.get('category_id')) {
    return <ChooseCategory list={categoryList} />
  }

  return (
    <>
      <CreateFile />
    </>
  )
}

export default Scan
