import React, { FC } from 'react'
import SvgIcon from '../SvgIcon/SvgIcon'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks'

type PropsType = {}

const ScanTo: FC<PropsType> = () => {
  const navigate = useNavigate()
  const { category_id, folder_id } = useParams()
  const { iconColor } = useAppSelector((state) => state.page)

  const handleClick = () => {
    let navigateTo = '/scan'

    if (category_id) {
      navigateTo += `?category_id=${category_id}`
    }

    if (folder_id) {
      navigateTo += `&folder_id=${folder_id}`
    }

    navigate(navigateTo)
  }

  return (
    <ButtonIcon color={iconColor} width={56} height={56} onClick={handleClick}>
      <SvgIcon width={32} height={32} name="scan" />
    </ButtonIcon>
  )
}

export default ScanTo
