import React, { FC } from 'react'
import { Button } from 'antd-mobile-v5'
import SvgIcon from '../SvgIcon/SvgIcon'
import { ContextMenuItemType } from '../../types'
import './PopapMenu.scss'

type PropsType = {
  actions: Array<ContextMenuItemType>
  onAction?: Function
}

const PopupMenu: FC<PropsType> = ({ actions, onAction }) => {
  const handleClick = (key: string, title: string) => {
    if (onAction) {
      onAction(key, title)
    }
  }

  return (
    <div className="popup-menu">
      {actions.map((item) => (
        <Button
          key={item.key}
          color={item.key === 'delete' ? 'danger' : 'default'}
          fill="none"
          block
          onClick={() => handleClick(item.key, item.title)}>
          {item.icon && <SvgIcon name={item.icon} />}
          {item.title}
          {item.more && (
            <SvgIcon
              name="angle-right"
              width={14}
              style={{ marginLeft: 'auto' }}
            />
          )}
        </Button>
      ))}
    </div>
  )
}

export default PopupMenu
