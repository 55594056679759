import React, { FC, useState } from 'react'
import { Badge, Button, Popup } from 'antd-mobile-v5'
import { Container, Title, Text } from '../../UI'
import img from '../../../assets/img/paywall-premium-update.svg'
import styles from './PaywallPremium.module.scss'
import ChoicePlan from '../../ChoicePlan/ChoicePlan'
import { setPaywall } from '../../../store/reducers/paywall'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import dayjs from 'dayjs'
import { updateProfile } from '../../../store/actions/profile'
import { useAuth } from '../../../hooks/useAuth'

interface PropsType {
  isVisible: boolean
  onSuccess: () => void
  onClose: () => void
}

type ChoiceType = 'month' | 'year'

const choices = [
  {
    value: 'month',
    title: '$12.99 / month',
    caption: '$12.99 billed monthly',
  },
  {
    value: 'year',
    title: '$4.16 / month',
    caption: '$49.99 billed yearly',
    badge: 'Save 68%',
  },
]

const PaywallPremiumUpdate: FC<PropsType> = ({
  isVisible,
  onSuccess,
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const { user } = useAuth()
  const profile = useAppSelector((state) => state.profile)
  const [choice, setChoice] = useState<ChoiceType>('year')

  const handleClickActivate = async () => {
    const dateTo = dayjs().add(1, choice)

    await dispatch(
      updateProfile(user.id, {
        ...profile,
        active_till: dateTo.format(),
      })
    )

    onSuccess()
  }

  return (
    <Popup
      visible={isVisible}
      bodyStyle={{
        paddingTop: 20,
        height: '100vh',
        textAlign: 'center',
        overflowY: 'scroll',
        borderRadius: 0,
      }}
      onMaskClick={onClose}
    >
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '100%',
        }}
      >
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '0 -10px 10px',
            }}
          >
            <Button
              color="default"
              fill="none"
              style={{ color: 'var(--adm-color-text-secondary)' }}
            >
              Restore
            </Button>

            <Button
              color="default"
              fill="none"
              style={{ color: 'var(--adm-color-text-secondary)' }}
              onClick={onClose}
            >
              Not Now
            </Button>
          </div>
        </div>

        <div style={{ marginTop: -66 }}>
          <img src={img} alt="Premium mode" />
        </div>

        <div style={{ margin: '40px 0' }}>
          <Title level={1} tag="div" style={{ marginBottom: 15 }}>
            Premium mode
          </Title>

          <Text size="lg">
            Get access to unlimited pages & folders, advanced visual edition and
            document sign in
          </Text>
        </div>

        <ChoicePlan
          theme="primary"
          choices={choices}
          value={choice}
          onChange={(value: ChoiceType) => setChoice(value)}
        />

        <div style={{ marginTop: 25, width: '100%' }}>
          <Button
            color="primary"
            size="large"
            block
            onClick={handleClickActivate}
          >
            Continue
          </Button>

          <Text color="secondary" style={{ marginTop: 30 }}>
            By purchasing, you agree to our{' '}
            <a style={{ color: 'var(--adm-color-text)' }}>Terms of Use</a> and{' '}
            <a style={{ color: 'var(--adm-color-text)' }}>Privacy Policy</a>.
            Cancel anytime for any reason
          </Text>
        </div>
      </Container>
    </Popup>
  )
}

export default PaywallPremiumUpdate
