import React, { FC, CSSProperties } from 'react'

type PropsType = {
  name: string
  width?: number
  height?: number
  style?: CSSProperties
}

const SvgIcon: FC<PropsType> = ({
  name,
  width = 24,
  height = 24,
  style = {},
}) => {
  return (
    <svg
      className="svg-icon"
      style={{ width: width, height: height, ...style }}
    >
      <use xlinkHref={`#${name}`} />
    </svg>
  )
}

export default SvgIcon
