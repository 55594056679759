import React, { FC } from 'react'
import { Modal } from 'antd-mobile-v5'
import { Text, Title } from '../../UI'
import Preview from '../../Preview/Preview'

type PropsType = {
  visible: boolean
  title: string
  heading: string
  color?: string
  img?: string | null
  url?: string
  onSubmit: Function
  onClose: Function
}

const ModalDelete: FC<PropsType> = ({
  visible,
  title,
  heading,
  color,
  img,
  url,
  onSubmit,
  onClose,
}) => {
  const pathToImg = img ? `/img/content/${img}.svg` : ''

  const handleClickOnDelete = async () => {
    await onSubmit()
    await onClose()
  }

  return (
    <Modal
      visible={visible}
      header={
        <>
          <Preview
            color={color}
            img={pathToImg}
            url={url}
            style={{ margin: '0 auto' }}
          />
          <Title tag="div" level={5} style={{ marginTop: 15 }}>
            {title}
          </Title>
        </>
      }
      title={
        <Title tag="div" level={4} bold style={{ marginTop: 20 }}>
          {heading}
        </Title>
      }
      content={<Text>This action cannot be undone</Text>}
      onClose={() => onClose()}
      actions={[
        {
          key: 'delete',
          text: 'Delete',
          primary: true,
          className: 'adm-button-danger',
          onClick: handleClickOnDelete,
        },
        {
          key: 'cancel',
          text: 'Cancel',
          onClick: () => onClose(),
        },
      ]}
    />
  )
}

export default ModalDelete
