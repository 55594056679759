import React, { FC, useContext } from 'react'
import Preview from '../Preview/Preview'
import { Title } from '../UI'
import SvgIcon from '../SvgIcon/SvgIcon'
import { CategoryContext } from './MoveTo'

type PropsType = {
  id: string
  color: string
  title: string
  selected: boolean
  url?: string | null
  img?: string | null
  onClick: Function
}

const MoveToSelect: FC<PropsType> = ({
  id,
  color,
  url,
  img,
  title,
  selected,
  onClick,
}) => {
  const { category_id } = useContext(CategoryContext)

  const handleClick = (category_id: string, id: string) => {
    onClick(category_id, id)
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 30,
        cursor: 'pointer',
      }}
      onClick={() => handleClick(category_id, id)}
    >
      <Preview
        width={32}
        height={28}
        color={color}
        url={url}
        img={img}
        style={{ position: 'relative', top: -2, marginRight: 20 }}
      />
      <Title level={5} tag="div">
        {title}
      </Title>

      {selected && <SvgIcon name="choice" style={{ marginLeft: 'auto' }} />}
    </div>
  )
}

export default MoveToSelect
