import {
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  orderBy,
  startAt,
  endAt,
} from 'firebase/firestore'
import { createCollection } from '../../firebase'
import {
  addFolder,
  removeFolder,
  setFolderList,
  updateFolder,
} from '../reducers/folder'
import { AppDispatchType } from '../index'
import { IFolder, IFolderCreate, SortType } from '../../types'
import { SelectedType } from '../../components/MoveTo/MoveTo'
import { supabase } from '../../supabase'
import { sortBy } from '../../helpers'

const TABLE_NAME: string = 'folders'
const COLLECTION_FOLDER: string = 'folders'

export const getFolderList =
  (parent_id: string, setToStore = true) =>
  async (dispatch: AppDispatchType) => {
    try {
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .select()
        .eq('parent_id', parent_id)

      if (error) throw new Error(error.message)

      if (setToStore) {
        dispatch(setFolderList(data))
      }

      return data
    } catch (e) {
      console.log(e)
      return []
    }
  }

export const sortFolderList =
  (sort: SortType) => async (dispatch: AppDispatchType, getState: any) => {
    const list = getState().folder.folderList
    const sorted = sortBy(list, 'title', sort)
    dispatch(setFolderList(sorted))
  }

export const createFolder =
  ({ title, user_id, category_id, parent_id }: IFolderCreate) =>
  async (dispatch: AppDispatchType) => {
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .insert({
        title,
        user_id,
        category_id,
        parent_id: parent_id || category_id,
      })
      .select()
      .single()

    if (error) {
      console.log('error', error.message)
      return
    }

    dispatch(addFolder(data as IFolder))
  }

export const renameFolder =
  (id: string, title: string) => async (dispatch: AppDispatchType) => {
    const { error } = await supabase
      .from(TABLE_NAME)
      .update({ title })
      .eq('id', id)

    if (error) console.log('error', error)

    dispatch(updateFolder({ id, title }))
  }

export const deleteFolder =
  (id: string) => async (dispatch: AppDispatchType) => {
    await supabase.from(TABLE_NAME).delete().eq('id', id)

    const recursiveDelete = async (id: string) => {
      const { data } = await supabase
        .from(TABLE_NAME)
        .select('id')
        .eq('parent_id', id)

      data?.forEach(({ id }) => {
        supabase
          .from(TABLE_NAME)
          .delete()
          .eq('id', id)
          .select('id')
          .single()
          .then((result) => {
            recursiveDelete(result.data?.id)
          })
      })
    }

    await recursiveDelete(id)

    dispatch(removeFolder(id))
  }

export const moveFolder =
  (id: string, move: SelectedType) => async (dispatch: AppDispatchType) => {
    try {
      const { error } = await supabase
        .from(TABLE_NAME)
        .update({
          parent_id: move.id,
          category_id: move.category_id,
        })
        .eq('id', id)

      if (error) throw new Error(error.message)

      const recursiveMove = async (id: string) => {
        const { data } = await supabase
          .from(TABLE_NAME)
          .select('id')
          .eq('parent_id', id)

        data?.forEach(({ id }) => {
          supabase
            .from(TABLE_NAME)
            .update({
              category_id: move.category_id,
            })
            .eq('id', id)
            .select('id')
            .single()
            .then((result) => {
              recursiveMove(result.data?.id)
            })
        })
      }

      await recursiveMove(id)

      dispatch(removeFolder(id))
    } catch (error) {
      console.log(error)
    }
  }

export const searchFolders =
  (title: string) => async (dispatch: AppDispatchType) => {
    try {
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .select()
        .textSearch('title', title)

      if (error) return console.log(error)

      return data
    } catch (error) {
      console.log(error)
    }
  }
