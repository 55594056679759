import React from 'react'
import { Outlet } from 'react-router-dom'

const LayoutAuth = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 20px',
        height: '100vh',
      }}>
      <img
        src="/logo192.png"
        alt=""
        width={100}
        height={100}
        style={{ margin: '0 auto 40px' }}
      />
      <Outlet />
    </div>
  )
}

export default LayoutAuth
