import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from 'antd-mobile-v5'
import { IFile, IFolder } from '../../types'
import { createFolder, moveFolder } from '../../store/actions/folder'
import { useAppDispatch, useAppSelector } from '../../hooks'
import AddCardButton from '../AddCardButton/AddCardButton'
import FolderCard from '../FolderCard/FolderCard'
import FileCard from '../FileCard/FileCard'
import { setFileList } from '../../store/reducers/file'
import ModalFormCreate from '../Modal/ModalFormCreate/ModalFormCreate'
import { useAuth } from '../../hooks/useAuth'
import MoveTo, { SelectedType } from '../MoveTo/MoveTo'
import { moveFile } from '../../store/actions/file'
import PaywallUnlimitedAccess from '../Paywall/PaywallUnlimitedAccess/PaywallUnlimitedAccess'
import { getCountFolders } from '../../store/actions/profile'
import dayjs from 'dayjs'

type ParamsType = {
  category_id: string
  folder_id: string | null
}

export type MoveType = {
  id: string
  parent_id: string | null
}

type PropsType = {
  list: IFolder[]
  files: IFile[]
  isGrid?: boolean
  loading: boolean
}

const FolderList: FC<PropsType> = ({ list, files, isGrid, loading }) => {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const profile = useAppSelector((state) => state.profile)
  const { category_id, folder_id } = useParams() as ParamsType
  const [isModalCreate, setIsModalCreate] = useState<boolean>(false)
  const [isPaywallUnlimited, setIsPaywallUnlimited] = useState<boolean>(false)
  const [moveTitle, setMoveTitle] = useState<string>('')
  const [move, setMove] = useState<MoveType>({
    id: '',
    parent_id: '',
  })
  const { color } = useAppSelector((state) => state.page)

  const handleUnlimitedAccessSuccess = async () => {
    setIsPaywallUnlimited(false)
    setIsModalCreate(true)
  }

  const handleOpenCreateModal = async () => {
    const countCreatedFolders = await dispatch(getCountFolders())

    if (
      countCreatedFolders < profile.folders ||
      dayjs(profile.active_till).diff(dayjs(), 'minute') > 0
    ) {
      setIsModalCreate(true)
    } else {
      setIsPaywallUnlimited(true)
    }
  }

  const handleCreate = async (value: string) => {
    await dispatch(
      createFolder({
        title: value,
        category_id: category_id,
        parent_id: folder_id,
        user_id: user.id,
      })
    )
  }

  const handleMove = (id: string, parent_id: string | null, title: string) => {
    setMoveTitle(title)
    setMove({ id, parent_id })
  }

  const handleMoveSubmit = async (selected: SelectedType) => {
    if (moveTitle === 'folder') {
      await dispatch(moveFolder(move.id, selected))
    }

    if (moveTitle === 'file') {
      await dispatch(moveFile(move.id, selected))
    }

    setMove({
      id: '',
      parent_id: '',
    })
    setMoveTitle('')
  }

  useEffect(() => {
    return () => {
      dispatch(setFileList([]))
    }
  }, [category_id])

  return (
    <>
      {user && (
        <>
          <Grid columns={isGrid ? 3 : 1} gap={isGrid ? 48 : 30}>
            {list.map((item) => (
              <Grid.Item key={item.id}>
                <FolderCard
                  data={item}
                  isGrid={isGrid}
                  color={color}
                  controls
                  onMove={() => handleMove(item.id, item.parent_id, 'folder')}
                />
              </Grid.Item>
            ))}

            {files.map((item) => (
              <Grid.Item key={item.id}>
                <FileCard
                  data={item}
                  isGrid={isGrid}
                  controls
                  onMove={() => handleMove(item.id, item.parent_id, 'file')}
                />
              </Grid.Item>
            ))}

            {!loading && (
              <Grid.Item>
                <AddCardButton
                  title="New Folder"
                  isGrid={isGrid}
                  folder
                  onClick={handleOpenCreateModal}
                />
              </Grid.Item>
            )}
          </Grid>

          <MoveTo
            isVisible={!!move.id}
            title={moveTitle}
            move={move}
            onMove={handleMoveSubmit}
            onClose={() => setMove({ id: '', parent_id: '' })}
          />

          <ModalFormCreate
            visible={isModalCreate}
            placeholder="Folder Name"
            onSubmit={handleCreate}
            onClose={() => setIsModalCreate(false)}
          />

          <PaywallUnlimitedAccess
            isVisible={isPaywallUnlimited}
            onSuccess={handleUnlimitedAccessSuccess}
            onClose={() => setIsPaywallUnlimited(false)}
          />
        </>
      )}
    </>
  )
}

export default FolderList
