import React, { FC, useState, useEffect, createContext } from 'react'
import { Button, Popup } from 'antd-mobile-v5'
import Title from '../UI/Title/Title'
import { useAppDispatch } from '../../hooks'
import { getCategoryList } from '../../store/actions/category'
import { useAuth } from '../../hooks/useAuth'
import { ICategory, IFolder } from '../../types'
import { Container } from '../UI'
import { getFolderList } from '../../store/actions/folder'
import Tree from './Tree'
import Select from './Select'
import { MoveType } from '../FolderList/FolderList'

type PropsType = {
  move: MoveType
  title: string
  isVisible: boolean
  onClose: Function
  onMove: Function
}

type CategoryContextType = {
  category_id: string
  categoryColor: string
}

export type SelectedType = {
  category_id: string
  id: string | null
}

export const CategoryContext = createContext({} as CategoryContextType)

const MoveTo: FC<PropsType> = ({ title, move, isVisible, onClose, onMove }) => {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const [categoryList, setCategoryList] = useState<ICategory[]>([])
  const [selected, setSelected] = useState<SelectedType>({
    category_id: '',
    id: '',
  })

  const fetch = async () => {
    if (user) {
      const data = await dispatch(getCategoryList())

      setCategoryList(data.map((item) => ({ ...item, folders: [] })))
    }
  }

  const handleChange = (category_id: string, id: string) => {
    setSelected({
      category_id,
      id,
    })
  }

  const handleClose = () => {
    setSelected({
      category_id: '',
      id: '',
    })
    onClose()
  }

  const handleGetListFolder = async (
    category_id: string,
    folder_id: string | null
  ) => {
    if (user) {
      const folders = await dispatch(getFolderList(category_id, false))

      const data = categoryList.map((item) => {
        if (item.id === category_id) {
          return {
            ...item,
            folders,
          }
        }

        return item
      })

      setCategoryList(data)
      setSelected({
        category_id,
        id: folder_id,
      })
    }
  }

  useEffect(() => {
    fetch()

    return () => {
      setCategoryList([])
    }
  }, [])

  // @ts-ignore
  return (
    <Popup
      visible={isVisible}
      bodyStyle={{ maxHeight: '90vh', overflowY: 'scroll' }}
      destroyOnClose
      onMaskClick={handleClose}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 10px',
          marginBottom: 15,
        }}
      >
        <div style={{ flex: '0 0 20%' }}>
          <Button color="default" fill="none" onClick={handleClose}>
            Cancel
          </Button>
        </div>

        <div style={{ flex: '1 1 auto', textAlign: 'center' }}>
          <Title level={5} tag="div" bold>
            Move {title}
          </Title>
        </div>

        <div style={{ flex: '0 0 20%', textAlign: 'right' }}>
          {(selected.category_id || selected.id) && (
            <Button
              color="primary"
              fill="none"
              onClick={() => onMove(selected)}
            >
              Move
            </Button>
          )}
        </div>
      </div>

      <Container>
        <div className="move-to">
          {categoryList.map((item) => (
            <CategoryContext.Provider
              key={item.id}
              value={{ category_id: item.id, categoryColor: item.color }}
            >
              <Select
                id={item.id}
                color={item.color}
                img={item.img}
                title={item.title}
                selected={
                  item.id === selected.category_id && item.id === selected.id
                }
                onClick={handleGetListFolder}
              />

              {item.folders && (
                <div style={{ paddingLeft: 30 }}>
                  <Tree
                    key={`parent-${item.id}`}
                    data={item.folders}
                    move={move}
                    selected={selected}
                    onChange={handleChange}
                  />
                </div>
              )}
            </CategoryContext.Provider>
          ))}
        </div>
      </Container>
    </Popup>
  )
}

export default MoveTo
