import { configureStore } from '@reduxjs/toolkit'
import { categoryReducer } from './reducers/category'
import { fileReducer } from './reducers/file'
import { folderReducer } from './reducers/folder'
import { profileReducer } from './reducers/profile'
import { pageReducer } from './reducers/page'
import { paywallReducer } from './reducers/paywall'

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    page: pageReducer,
    file: fileReducer,
    folder: folderReducer,
    category: categoryReducer,
    paywall: paywallReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

export type RootStateType = ReturnType<typeof store.getState> // A global type to access reducers types
export type AppDispatchType = typeof store.dispatch // Type to access dispatch
