import randomColor from 'randomcolor'
import { SortType, ICategory } from '../../types'
import { setPage } from '../reducers/page'
import {
  setCategoryList,
  addCategory,
  updateCategory,
  removeCategory,
} from '../reducers/category'
import { AppDispatchType } from '../index'
import { sortBy } from '../../helpers'
import { supabase } from '../../supabase'
import { ProfileType, setProfile } from '../reducers/profile'

const TABLE_NAME: string = 'profiles'

export const getProfile = (id: string) => async (dispatch: AppDispatchType) => {
  try {
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .select()
      .eq('id', id)
      .single()

    dispatch(setProfile(data))

    if (error) throw new Error(error.message)

    return data
  } catch (e) {
    console.log(e)

    return {
      active_till: null,
      files: 0,
      folders: 0,
      id: null,
      subscribe: null,
    }
  }
}

export const getCountFolders = () => async (dispatch: AppDispatchType) => {
  const [categories, folders] = await Promise.all([
    supabase.from('categories').select(),
    supabase.from('folders').select(),
  ])

  if (categories?.data && folders?.data) {
    return categories.data.length + folders.data.length - 6
  }

  return 0
}

export const getCountFiles = () => async (dispatch: AppDispatchType) => {
  const { data: files } = await supabase.from('files').select()

  return files ? files.length : 0
}

export const updateProfile =
  (id: string, form: ProfileType) => async (dispatch: AppDispatchType) => {
    try {
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .update(form)
        .eq('id', id)
        .select()
        .single()

      dispatch(setProfile(data))

      if (error) throw new Error(error.message)
    } catch (e) {
      console.log(e)
    }
  }
