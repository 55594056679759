import React, { FC } from 'react'
import Title from '../UI/Title/Title'
import colorAlpha from 'color-alpha'

type PropsType = {
  color?: string
  img?: string | null
  url?: string | null
  placeholder?: string
  width?: number
  height?: number
  style?: object
}

const Preview: FC<PropsType> = ({
  color = '#02AAFF',
  img,
  url,
  placeholder,
  width = 80,
  height = 70,
  style,
}) => {
  const colorLight = colorAlpha(color, 0.21)

  return (
    <div
      className="preview"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 1,
        width: width,
        height: height,
        ...style,
      }}
    >
      {url ? (
        <img
          src={url}
          alt=""
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: 'auto',
            height: 'auto',
          }}
        />
      ) : (
        <img
          src={`/img/content/${img}.svg`}
          alt=""
          style={{ maxWidth: '80%', maxHeight: '60%' }}
        />
      )}

      {placeholder && (
        <Title level={1} tag="div" style={{ color: '#fff' }}>
          {placeholder}
        </Title>
      )}

      {!url && (
        <>
          <svg
            width="80"
            height="70"
            viewBox="0 0 80 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: -2,
              width: '100%',
              height: 'auto',
            }}
          >
            <path
              d="M79.6825 10.4805C79.3929 9.10323 78.8822 7.78318 78.8427 6.36307C76.5521 6.11162 74.4331 6.04444 72.1208 6.04963C69.7048 6.04963 65.925 5.35761 63.5983 4.73305C46.75 4.21986 48.8147 5.97592 41.428 6.18798C37.4958 6.30503 33.5517 5.28208 29.6414 5.78698C29.4646 4.8193 29.2149 0.348943 28.268 0C26.2296 0.272488 23.2777 0.173716 21.2245 0C19.3693 0 17.5872 0.909674 15.7311 0.845662C12.9158 0.752451 10.8885 0.631116 8.09742 0.294047C5.53314 0.515655 2.35713 0.653214 0 0C0.607135 4.52386 1.91052 9.23806 0 13.7079C0.192651 15.8224 1.55301 21.7053 1.75 23.8298C2.3477 30.3072 0.699084 33.6359 0.0743313 40.0826C0.0743313 45.0744 1.75 46.4184 0.30737 54.4092C0.243902 59.3519 0.651933 64.3448 1.05001 69.2708C13.7266 67.7151 17.3082 71.2468 28.4658 69.5037C34.9581 68.4894 42.7735 69.5313 45.1164 69.9085C47.4324 69.3956 51.7729 69.0207 54.0174 69.9847C56.6016 69.6648 63.0659 68.8363 65.6712 68.8942C71.489 69.0134 73.3297 69.0392 79.1501 69.4723C79.6297 63.9918 77.25 59.3262 78.6653 52.8723C79.5906 48.6525 77.4122 42.722 78.6653 37.7305C80.3477 31.0284 77.5837 29.5493 78.7838 22.4033C79.4443 18.5082 80.5465 14.4319 79.6825 10.4805Z"
              fill={colorLight}
            />
          </svg>

          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: -1,
              width: '100%',
              height: '100%',
              backgroundImage:
                'linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(255,255,255,0.5) 100%)',
            }}
          />
        </>
      )}
    </div>
  )
}

export default Preview
