import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../hooks'
import dayjs from 'dayjs'
import { Document, Page } from 'react-pdf'
import { getFile } from '../store/actions/file'
import { useAuth } from '../hooks/useAuth'
import { IFile } from '../types'
import { Title, Text } from '../components/UI'
import ButtonIcon from '../components/ButtonIcon/ButtonIcon'
import SvgIcon from '../components/SvgIcon/SvgIcon'
import { useNavigate } from 'react-router'

const File = () => {
  const navigate = useNavigate()
  const [file, setFile] = useState<IFile>()
  const { user } = useAuth()
  const { fileId } = useParams()
  const dispatch = useAppDispatch()

  const fetch = async () => {
    if (fileId && user) {
      const payload = (await dispatch(getFile(fileId))) as IFile

      console.log(payload)

      setFile({
        ...payload,
        created_at: dayjs(payload.created_at).format('MM/DD/YYYY'),
        size: parseInt(String(payload.size / 1024)),
      })
    }
  }

  const onDocumentLoadSuccess = () => {
    console.log('onDocumentLoadSuccess')
  }

  useEffect(() => {
    fetch()
  }, [user, fileId])

  return (
    <>
      {file && (
        <div style={{ height: '100vh' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              padding: '5px 40px',
              height: 60,
              textAlign: 'center',
            }}
          >
            <ButtonIcon
              onClick={() => navigate(-1)}
              style={{ position: 'absolute', top: 10, left: 10 }}
            >
              <SvgIcon name="angle-left" width={18} />
            </ButtonIcon>
            <Title level={5} tag="h1" bold style={{ marginBottom: 3 }}>
              {file.title}.{file.extension}
            </Title>
            <Text color="secondary">
              {file.created_at}, {file.size.toLocaleString()} kb
            </Text>
          </div>

          <Document file={file.url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page />
          </Document>

          <img
            src={file.url}
            alt=""
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      )}
    </>
  )
}

export default File
