import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { IFolder } from '../../types'
import { useAppDispatch } from '../../hooks'
import SvgIcon from '../SvgIcon/SvgIcon'
import Preview from '../Preview/Preview'
import { Text, Title } from '../UI'
import './FolderCard.scss'
import ModalFormRename from '../Modal/ModalFormRename/ModalFormRename'
import ModalDelete from '../Modal/ModalDelete/ModalDelete'
import { deleteFolder, renameFolder } from '../../store/actions/folder'
import { ActionSheet } from 'antd-mobile-v5'
import ActionSheetButton from '../ActionSheetButton/ActionSheetButton'

interface PropsType {
  data: IFolder
  color?: string
  controls?: boolean
  isGrid?: boolean
  onMove?: Function
  onClick?: Function
}

const FolderCard: FC<PropsType> = ({
  data,
  color,
  controls,
  isGrid,
  onClick,
  onMove,
}) => {
  const dispatch = useAppDispatch()
  const [isContextMenu, setIsContextMenu] = useState<boolean>(false)
  const [isModalRename, setIsModalRename] = useState<boolean>(false)
  const [isModalDelete, setIsModalDelete] = useState<boolean>(false)

  const contextMenuActions = [
    {
      text: <ActionSheetButton icon="rename" title="Rename" />,
      key: 'rename',
    },
    {
      text: <ActionSheetButton icon="move" title="Move" />,
      key: 'move',
    },
    {
      text: <ActionSheetButton icon="delete" title="Delete" />,
      key: 'delete',
      danger: true,
    },
  ]

  const handleRename = async (value: string) => {
    await dispatch(renameFolder(data.id, value))
  }

  const handleDelete = async () => {
    await dispatch(deleteFolder(data.id))
  }

  const handleMenuAction = (key: string | number) => {
    switch (key) {
      case 'rename':
        setIsModalRename(true)
        setIsContextMenu(false)
        break

      case 'move':
        if (onMove) {
          onMove(data.id, data.parent_id)
        }
        setIsContextMenu(false)
        break

      case 'delete':
        setIsModalDelete(true)
        setIsContextMenu(false)
        break
    }
  }

  return (
    <>
      <div className={`folder-card ${isGrid ? 'is-grid' : 'is-list'}`}>
        <Link
          to={`/category/${data.category_id}/folder/${data.id}`}
          className="folder-card__link"
        >
          <Preview
            color={color}
            width={isGrid ? 80 : 32}
            height={isGrid ? 70 : 28}
          />
          <Text size="lg">{data.title}</Text>
        </Link>

        {controls && (
          <span
            className="folder-card__more"
            onClick={() => setIsContextMenu(true)}
          >
            <SvgIcon name="dots-v" />
          </span>
        )}
      </div>

      <ActionSheet
        visible={isContextMenu}
        onMaskClick={() => setIsContextMenu(false)}
        onAction={({ key }) => handleMenuAction(key)}
        extra={
          <>
            <Preview color={color} width={24} height={21} />

            <Title level={4} bold>
              {data.title}
            </Title>
          </>
        }
        actions={contextMenuActions}
      />

      <ModalFormRename
        visible={isModalRename}
        title="Rename folder"
        text="Enter a name for your folder"
        placeholder="Category Name"
        value={data.title}
        onSubmit={handleRename}
        onClose={() => setIsModalRename(false)}
      />

      <ModalDelete
        visible={isModalDelete}
        heading="Delete folder and all data?"
        title={data.title}
        color={color}
        onSubmit={handleDelete}
        onClose={() => setIsModalDelete(false)}
      />
    </>
  )
}

export default FolderCard
