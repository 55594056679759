import React, { useState } from 'react'
import { Button, Form, Input } from 'antd-mobile-v5'
import { useNavigate } from 'react-router'
import { useAuth } from '../../hooks/useAuth'

type FormType = {
  email: string
  password: string
}

const Login = () => {
  const navigate = useNavigate()
  const { signin, signupWithGoogle } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)

  const handleFormSubmit = async ({ email, password }: FormType) => {
    setLoading(true)
    await signin(email, password)
    setLoading(false)
  }

  return (
    <Form
      layout="horizontal"
      onFinish={handleFormSubmit}
      footer={
        <>
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={loading}
          >
            Sign in
          </Button>

          <Button
            block
            type="button"
            color="primary"
            size="large"
            fill="none"
            style={{ marginTop: 15 }}
            onClick={() => signupWithGoogle()}
          >
            Login with Google
          </Button>

          <Button
            block
            type="button"
            color="primary"
            size="large"
            fill="none"
            style={{ marginTop: 15 }}
            onClick={() => navigate('/register')}
          >
            Sign up
          </Button>
        </>
      }
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true }, { type: 'email' }]}
      >
        <Input onChange={console.log} placeholder="Enter email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, min: 6 }]}
      >
        <Input
          onChange={console.log}
          placeholder="Enter password"
          type="password"
        />
      </Form.Item>
    </Form>
  )
}

export default Login
