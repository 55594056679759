import React, { createContext, useEffect } from 'react'
import { Route, Routes } from 'react-router'
import SvgSprite from './components/SvgSprite/SvgSprite'
import LayoutDefault from './layout/default/default'
import LayoutAuth from './layout/auth'
import HomePage from './pages/Home'
import ScanPage from './pages/Scan'
import CategoryPage from './pages/Category'
import FolderPage from './pages/Folder'
import FilePage from './pages/File'
import SearchPage from './pages/Search'
import LoginPage from './pages/Auth/Login'
import RegisterPage from './pages/Auth/Register'
import { ConfigProvider } from 'antd-mobile-v5'
import enUS from 'antd-mobile-v5/es/locales/en-US'
import { useLocation } from 'react-router-dom'

const App = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0))
  }, [pathname])

  return (
    <ConfigProvider locale={enUS}>
      <Routes>
        <Route path="/" element={<LayoutDefault />}>
          <Route index path="/dashboard" element={<HomePage />} />
          <Route path="/category/:category_id" element={<CategoryPage />} />
          <Route
            path="/category/:category_id/folder/:folder_id"
            element={<FolderPage />}
          />
        </Route>

        <Route path="/file/:fileId" element={<FilePage />} />

        <Route path="/search" element={<SearchPage />} />
        <Route path="/scan" element={<ScanPage />} />

        <Route path="/" element={<LayoutAuth />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
        </Route>
      </Routes>

      <SvgSprite />
    </ConfigProvider>
  )
}

export default App
