import React, { FC, useState, useEffect } from 'react'
import { ICategory, IFolder } from '../../types'
import TreeNode from './TreeNode'
import { SelectedType } from './MoveTo'
import { MoveType } from '../FolderList/FolderList'

type DataType = ICategory[] | IFolder[]

type PropsType = {
  move: MoveType
  selected: SelectedType
  data: DataType
  onChange?: Function
}

const Tree: FC<PropsType> = ({ data, move, selected, onChange }) => {
  const [filteredData, setFilteredData] = useState<DataType>([])

  const handleOnChange = (category_id: string, id: string) => {
    if (onChange) {
      onChange(category_id, id)
    }
  }

  useEffect(() => {
    // @ts-ignore
    const filtered = data.filter(
      (item: { id: string }): boolean => item.id !== move.id
    )
    setFilteredData(filtered)
  }, [data])

  return (
    <>
      {filteredData.map((node) => (
        <>
          <TreeNode
            key={node.id}
            move={move}
            node={node}
            selected={selected}
            style={{ color: 'color' in node ? node.color : '' }}
            onChange={handleOnChange}
          />
        </>
      ))}
    </>
  )
}

export default Tree
