import React, { FC, useState } from 'react'
import { Button } from 'antd-mobile-v5'
import SvgIcon from '../SvgIcon/SvgIcon'
import { Text, Title } from '../UI'
import './ProVersionNotify.scss'
import PaywallPremium from '../Paywall/PaywallPremium/PaywallPremium'
import PaywallPremiumUpdate from '../Paywall/PaywallPremiumUpdate/PaywallPremiumUpdate'
import dayjs from 'dayjs'
import { useAppSelector } from '../../hooks'

type PropsType = {}

const ProVersionNotify: FC<PropsType> = () => {
  const profile = useAppSelector((state) => state.profile)
  const [isPaywallPremium, setIsPaywallPremium] = useState<boolean>(false)
  const [isPaywallPremiumUpdate, setIsPaywallPremiumUpdate] =
    useState<boolean>(false)

  const isPremium = dayjs(profile.active_till).diff(dayjs(), 'minute') > 0
  const activeTill = dayjs(profile.active_till).format('DD.MM.YY')

  const handlePremiumSuccess = () => {
    setIsPaywallPremium(false)
    setIsPaywallPremiumUpdate(false)
  }

  const handlePremiumOpen = () => {
    if (isPremium) {
      setIsPaywallPremiumUpdate(true)
    } else {
      setIsPaywallPremium(true)
    }
  }

  return (
    <>
      <div
        className="pro-version-notify"
        style={{ backgroundColor: isPremium ? '#FFEECC' : '#CCEEFF' }}
      >
        <div
          className="pro-version-notify__icon"
          style={{ color: isPremium ? '#FFAA00' : '#02AAFF' }}
        >
          <SvgIcon name="premium" />
        </div>
        <div className="pro-version-notify__content">
          <Text style={{ marginBottom: 2 }}>Pro-version</Text>
          <Title level={5} tag="div">
            {isPremium ? `Active till ${activeTill}` : 'Unlock All Features'}
          </Title>
        </div>
        <div className="pro-version-notify__button">
          <Button color="primary" fill="none" onClick={handlePremiumOpen}>
            Update
          </Button>
        </div>
      </div>

      <PaywallPremium
        isVisible={isPaywallPremium}
        onSuccess={handlePremiumSuccess}
        onClose={() => setIsPaywallPremium(false)}
      />

      <PaywallPremiumUpdate
        isVisible={isPaywallPremiumUpdate}
        onSuccess={handlePremiumSuccess}
        onClose={() => setIsPaywallPremiumUpdate(false)}
      />
    </>
  )
}

export default ProVersionNotify
