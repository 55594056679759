import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { RootStateType, AppDispatchType } from '../store'

//useDispatch hook with types.
export const useAppDispatch = () => useDispatch<AppDispatchType>()
//useSelector hook with types
export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector

export const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
