import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { getCategoryList, sortCategoryList } from '../store/actions/category'
import { setPage } from '../store/reducers/page'
import CategoryList from '../components/CategoryList/CategoryList'
import { useAuth } from '../hooks/useAuth'

const Home = () => {
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(true)
  const { sort, view } = useAppSelector((state) => state.page)
  const { categoryList } = useAppSelector((state) => state.category)

  const fetch = async () => {
    if (!categoryList.length) {
      await dispatch(getCategoryList())
    }

    setLoading(false)
  }

  useEffect(() => {
    dispatch(
      setPage({
        id: '',
        title: 'Home',
        color: '#fff',
        iconColor: '#02aaff',
      })
    )

    fetch()
  }, [])

  useEffect(() => {
    if (sort) {
      dispatch(sortCategoryList(sort))
    }
  }, [sort])

  return (
    <CategoryList
      list={categoryList}
      isGrid={view === 'grid'}
      loading={loading}
    />
  )
}

export default Home
