import React, { FC } from 'react'
import SvgIcon from '../SvgIcon/SvgIcon'

type PropsType = {
  icon: string
  title: string
}

const ActionSheetButton: FC<PropsType> = ({ icon, title }) => {
  return (
    <>
      <SvgIcon name={icon} /> <span>{title}</span>
    </>
  )
}

export default ActionSheetButton
