import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar'
import { useAppSelector } from '../../hooks'
import SvgIcon from '../../components/SvgIcon/SvgIcon'
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon'
import { Container, Title } from '../../components/UI'
import { useAuth } from '../../hooks/useAuth'
import styles from './default.module.scss'
import ScanTo from '../../components/ScanTo/ScanTo'
import PaywallPremium from '../../components/Paywall/PaywallPremium/PaywallPremium'
import PaywallSaveDocument from '../../components/Paywall/PaywallSaveDocument/PaywallSaveDocument'
import PaywallUnlimitedAccess from '../../components/Paywall/PaywallUnlimitedAccess/PaywallUnlimitedAccess'
import PaywallPremiumUpdate from '../../components/Paywall/PaywallPremiumUpdate/PaywallPremiumUpdate'

const LayoutDefault = () => {
  const auth = useAuth()
  const { pathname } = useLocation()
  const { color, title } = useAppSelector((state) => state.page)
  const paywall = useAppSelector((state) => state.paywall)

  return (
    <>
      <div className={styles.layout}>
        <Navbar />

        <div className={styles.header}>
          {pathname !== '/dashboard' && (
            <div
              className={styles.headerBg}
              style={{ backgroundColor: color }}
            />
          )}
          <Container>
            <Title level={1}>{title}</Title>
          </Container>
        </div>

        <Container>
          <Outlet context={auth} />
        </Container>

        <div className={styles.scan}>
          <ScanTo />
        </div>
      </div>
    </>
  )
}

export default LayoutDefault
