import React, { FC, CSSProperties, createElement } from 'react'
import './Text.scss'

type PropsType = {
  color?: string
  tag?: string
  size?: string
  bold?: boolean
  style?: CSSProperties
  children: React.ReactNode
}

const Text: FC<PropsType> = ({
  color = 'primary',
  size,
  children,
  tag = 'p',
  bold,
  style,
}) => {
  const classBold = bold ? 'text-bold' : ''
  const classSize = size ? `text-${size}` : ''
  const classNames = [`text text-${color}`, classBold, classSize].join(' ')

  return createElement(
    tag,
    {
      className: classNames,
      style,
    },
    children
  )
}

export default Text
