import React, {
  FC,
  ReactNode,
  CSSProperties,
  useState,
  useContext,
} from 'react'
import { ICategory, IFile, IFolder } from '../../types'
import Tree from './Tree'
import { useAppDispatch } from '../../hooks'
import { getFolderList } from '../../store/actions/folder'
import { getFileList } from '../../store/actions/file'
import { useAuth } from '../../hooks/useAuth'
import Select from './Select'

import { CategoryContext, SelectedType } from './MoveTo'
import { MoveType } from '../FolderList/FolderList'

type PropsType = {
  node: any
  move: MoveType
  selected: SelectedType
  style?: CSSProperties
  children?: ReactNode
  onChange: Function
}

const TreeNode: FC<PropsType> = ({ node, move, selected, style, onChange }) => {
  const { category_id, categoryColor } = useContext(CategoryContext)
  const { user } = useAuth()
  const dispatch = useAppDispatch()
  const [children, setChildren] = useState<ICategory[] | IFolder[] | IFile[]>(
    []
  )

  const [showChildren, setShowChildren] = useState({
    id: node.id,
    show: false,
  })

  const handleClick = async (category_id: string, folder_id: string) => {
    if (user) {
      const id = folder_id || category_id

      const [listFolder, listFile] = await Promise.all([
        await dispatch(getFolderList(id)),
        await dispatch(getFileList(id)),
      ])

      setChildren([...listFolder, ...listFile])
    }

    setShowChildren({
      ...showChildren,
      // show: !showChildren.show,
      show: true,
    })

    onChange(category_id, folder_id)
  }

  return (
    <div style={style}>
      <Select
        id={node.id}
        color={categoryColor}
        img={node.img}
        title={node.title}
        selected={selected.id === node.id}
        onClick={() => handleClick(category_id, node.id)}
      />

      {showChildren.show && children && (
        <div style={{ paddingLeft: '30px' }}>
          <Tree
            key={`children-${node.id}`}
            data={children}
            move={move}
            selected={selected}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  )
}

export default TreeNode
