import { initializeApp } from 'firebase/app'
import {collection, CollectionReference, DocumentData, getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
import { GoogleAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyAKuKotRCCdomt8g9rXpQHfMwl8pVKIV8Y",
  authDomain: "lifescan-5e6ea.firebaseapp.com",
  projectId: "lifescan-5e6ea",
  storageBucket: "lifescan-5e6ea.appspot.com",
  messagingSenderId: "582042048462",
  appId: "1:582042048462:web:5e131f2f779cd20e817d9f",
  measurementId: "G-QKEJ180RZ7"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const googleAuthProvider = new GoogleAuthProvider();

export const firestore = getFirestore(app);
export const storage = getStorage(app);

export const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>
}
