import React, {
  FC,
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react'
import {
  AuthChangeEvent,
  Session,
  User,
  SupabaseClient,
} from '@supabase/supabase-js'
import { supabase } from '../supabase'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Toast } from 'antd-mobile-v5'
import { useAppDispatch } from './index'
import { getProfile } from '../store/actions/profile'

interface AppContextInterface {
  user: User
  signin: Function
  signup: Function
  signout: Function
  signupWithGoogle: Function
}

const initialUser = {
  app_metadata: {},
  aud: '',
  confirmation_sent_at: '',
  confirmed_at: '',
  created_at: '',
  email: '',
  email_confirmed_at: '',
  id: '',
  identities: [],
  phone: '',
  role: '',
  updated_at: '',
  user_metadata: {},
}

const AuthContext = createContext<AppContextInterface>({
  user: initialUser,
  signin: () => {},
  signup: () => {},
  signout: () => {},
  signupWithGoogle: () => {},
})

type PropsType = {
  children: React.ReactNode
}

export const ProvideAuth: FC<PropsType> = ({ children }) => {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

function useProvideAuth() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [session, setSession] = useState<Session | null>(null)
  const [user, setUser] = useState<User | null>(null)

  const signin = async (email: string, password: string) => {
    try {
      const { data } = await supabase.auth.signInWithPassword({
        email,
        password,
      })
      setSession(data.session)
    } catch (e) {
      console.log(e)
      Toast.show({
        position: 'top',
        content: 'User not found',
        maskClassName: 'login-error',
      })
    }
  }

  const signup = async (email: string, password: string) => {
    const { data } = await supabase.auth.signUp({ email, password })
    setSession(data.session)
  }

  const signupWithGoogle = async () => {
    // const { user } = await signInWithPopup(auth, googleAuthProvider)
    // setUser(user)
    // return user
  }

  const signout = async () => {
    await supabase.auth.signOut()
    setSession(null)
  }

  useEffect(() => {
    supabase.auth.getSession().then(({ data }) => {
      setSession(data.session)

      if (data.session?.user.id) {
        dispatch(getProfile(data.session?.user.id))
      }

      if (data.session?.user && location.pathname === '/') {
        navigate('/dashboard')
      }

      if (!data.session?.user) {
        navigate('/login')
      }
    })

    supabase.auth.onAuthStateChange(
      (event: AuthChangeEvent, session: Session | null) => {
        setSession(session)

        if (session?.user && location.pathname === '/') {
          navigate('/dashboard')
        }
      }
    )
  }, [])

  return {
    user: session?.user || initialUser,
    signin,
    signup,
    signout,
    signupWithGoogle,
  }
}
