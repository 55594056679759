import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { Text, Title } from '../UI'
import { ICategory } from '../../types'
import ModalFormRename from '../Modal/ModalFormRename/ModalFormRename'
import ModalDelete from '../Modal/ModalDelete/ModalDelete'
import {
  deleteCategory,
  getCategory,
  renameCategory,
} from '../../store/actions/category'
import { useAppDispatch } from '../../hooks'
import './CategoryCard.scss'
import { ActionSheet } from 'antd-mobile-v5'
import Preview from '../Preview/Preview'
import ActionSheetButton from '../ActionSheetButton/ActionSheetButton'
import { useNavigate } from 'react-router'
import colorAlpha from 'color-alpha'
import { useAuth } from '../../hooks/useAuth'

interface PropsType {
  data: ICategory
  isGrid?: boolean
  onClick?: Function
}

const CategoryCard: FC<PropsType> = ({ data, isGrid, onClick }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isContextMenu, setIsContextMenu] = useState<boolean>(false)
  const [isModalRename, setIsModalRename] = useState<boolean>(false)
  const [isModalDelete, setIsModalDelete] = useState<boolean>(false)

  const lightenColor = colorAlpha(data.color, 0.21)

  const contextMenuActions = [
    {
      text: <ActionSheetButton icon="rename" title="Rename" />,
      key: 'rename',
      disabled: data.required,
    },
    {
      text: <ActionSheetButton icon="delete" title="Delete" />,
      key: 'delete',
      danger: true,
      disabled: data.required,
    },
  ]

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    setIsContextMenu(true)
  }

  const handleRename = async (value: string) => {
    await dispatch(renameCategory(data.id, value))
  }

  const handleDelete = async () => {
    await dispatch(deleteCategory(data.id))
  }

  const handleClick = async () => {
    if (onClick) {
      onClick(data.id)
    }
  }

  const handleMenuAction = (key: string | number) => {
    switch (key) {
      case 'rename':
        setIsModalRename(true)
        setIsContextMenu(false)
        break

      case 'delete':
        setIsModalDelete(true)
        setIsContextMenu(false)
        break
    }
  }

  return (
    <>
      <div
        className={`category-card ${isGrid ? 'is-grid' : 'is-list'}`}
        onClick={handleClick}
        onContextMenu={handleContextMenu}
      >
        <span className="category-card__icon">
          <img src={`/img/content/${data.img}.svg`} alt="" />
        </span>

        <Title level={4} bold style={{ marginBottom: 2 }}>
          {data.title}
        </Title>

        <Text color="secondary">
          {data.count} item{data.count !== 1 ? 's' : ''}
        </Text>

        {isGrid ? (
          <svg
            width="160"
            height="141"
            viewBox="0 0 160 141"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M159.365 21.1108C158.786 18.3365 157.764 15.6775 157.685 12.817C153.104 12.3105 148.866 12.1752 144.242 12.1857C139.41 12.1857 131.85 10.7918 127.197 9.53371C93.5 8.5 97.6294 12.0372 82.8559 12.4644C74.9917 12.7001 67.1034 10.6396 59.2829 11.6566C58.9293 9.70745 58.4299 0.70287 56.536 0C52.4592 0.548868 46.5555 0.349913 42.4491 0C38.7387 0 35.1744 1.83234 31.4623 1.7034C25.8316 1.51565 21.7771 1.27125 16.1948 0.592296C11.0663 1.03868 4.71427 1.31576 0 0C1.21427 9.11236 3.82104 18.6081 0 27.6117C0.385302 31.8708 3.10602 43.7207 3.5 48C4.69541 61.0473 1.39817 67.7523 0.148663 80.7379C0.148663 90.7928 3.5 93.5 0.61474 109.596C0.487804 119.552 1.30387 129.609 2.10003 139.531C27.4533 136.398 34.6165 143.511 56.9317 140C69.9161 137.957 85.547 140.056 90.2328 140.816C94.8648 139.783 103.546 139.027 108.035 140.969C113.203 140.325 126.132 138.656 131.342 138.773C142.978 139.013 146.659 139.065 158.3 139.937C159.259 128.898 154.5 119.5 157.331 106.5C159.181 98 154.824 86.0543 157.331 76C160.695 62.5 155.167 59.5206 157.568 45.1267C158.889 37.2807 161.093 29.07 159.365 21.1108Z"
              fill={lightenColor}
            />
          </svg>
        ) : (
          <svg
            width="335"
            height="70"
            viewBox="0 0 335 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M333.67 10.4805C332.458 9.10323 330.319 7.78318 330.154 6.36307C320.562 6.11162 311.689 6.04444 302.006 6.04963C291.889 6.04963 276.061 5.35761 266.318 4.73305C195.766 4.21986 204.411 5.97592 173.48 6.18798C157.014 6.30502 140.498 5.28208 124.124 5.78698C123.383 4.8193 122.338 0.348942 118.372 0C109.836 0.272488 97.4756 0.173716 88.8777 0C81.1091 0 73.6464 0.909674 65.8741 0.845662C54.0849 0.752451 45.5957 0.631116 33.908 0.294047C23.17 0.515655 9.8705 0.653214 0 0C2.54238 4.52386 8.00029 9.23806 0 13.7079C0.806726 15.8224 6.50322 21.7053 7.32812 23.8298C9.83101 30.3071 2.92741 33.6359 0.311262 40.0826C0.311262 45.0744 7.32812 46.4184 1.28711 54.4092C1.02134 59.3519 2.72997 64.3448 4.39693 69.2708C57.4803 67.7151 72.4782 71.2468 119.201 69.5037C146.387 68.4894 179.114 69.5313 188.925 69.9084C198.623 69.3956 216.799 69.0207 226.198 69.9846C237.019 69.6648 264.088 68.8362 274.998 68.8942C299.36 69.0134 307.068 69.0392 331.441 69.4723C333.449 63.9918 323.484 59.3262 329.411 52.8723C333.286 48.6525 324.164 42.722 329.411 37.7305C336.456 31.0284 324.882 29.5493 329.907 22.4033C332.673 18.5082 337.289 14.4319 333.67 10.4805Z"
              fill={lightenColor}
            />
          </svg>
        )}
      </div>

      <ActionSheet
        visible={isContextMenu}
        onMaskClick={() => setIsContextMenu(false)}
        onAction={({ key }) => handleMenuAction(key)}
        extra={
          <>
            <Preview color={lightenColor} width={24} height={21} />

            <Title level={4} bold>
              {data.title}
            </Title>
          </>
        }
        actions={contextMenuActions}
      />

      <ModalFormRename
        visible={isModalRename}
        title="Rename category"
        text="Enter a name for your category"
        placeholder="Category Name"
        value={data.title}
        onSubmit={handleRename}
        onClose={() => setIsModalRename(false)}
      />

      <ModalDelete
        visible={isModalDelete}
        heading="Delete category and all data?"
        title={data.title}
        color={data.color}
        img={data.img}
        onSubmit={handleDelete}
        onClose={() => setIsModalDelete(false)}
      />
    </>
  )
}

export default CategoryCard
