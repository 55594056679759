import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks'
import { getFolderList, sortFolderList } from '../store/actions/folder'
import { getCategory } from '../store/actions/category'
import FolderList from '../components/FolderList/FolderList'
import { getFileList } from '../store/actions/file'
import { setFolderList } from '../store/reducers/folder'
import { setFileList } from '../store/reducers/file'
import { useAuth } from '../hooks/useAuth'

const Category = () => {
  const { user } = useAuth()
  const { category_id = '' } = useParams()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(true)
  const { sort, view, id } = useAppSelector((state) => state.page)
  const { fileList } = useAppSelector((state) => state.file)
  const { folderList } = useAppSelector((state) => state.folder)

  const fetch = async () => {
    if (id !== category_id) {
      await dispatch(getCategory(category_id))
    }

    await Promise.all([
      await dispatch(getFolderList(category_id)),
      await dispatch(getFileList(category_id)),
    ])

    setLoading(false)
  }

  useEffect(() => {
    fetch()

    return () => {
      dispatch(setFolderList([]))
      dispatch(setFileList([]))
    }
  }, [user])

  useEffect(() => {
    if (sort) {
      dispatch(sortFolderList(sort))
    }
  }, [sort])

  return (
    <FolderList
      list={folderList}
      files={fileList}
      isGrid={view === 'grid'}
      loading={loading}
    />
  )
}

export default Category
