import React, { FC } from 'react'
import { Badge, Button, Popup } from 'antd-mobile-v5'
import { Container, Title, Text } from '../../UI'
import img from '../../../assets/img/paywall-premium.svg'
import styles from './PaywallPremium.module.scss'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getProfile, updateProfile } from '../../../store/actions/profile'
import { useAuth } from '../../../hooks/useAuth'
import { setPaywall } from '../../../store/reducers/paywall'

interface PropsType {
  isVisible: boolean
  onSuccess: () => void
  onClose: () => void
}

const PaywallPremium: FC<PropsType> = ({ isVisible, onSuccess, onClose }) => {
  const dispatch = useAppDispatch()
  const { user } = useAuth()
  const profile = useAppSelector((state) => state.profile)

  const handleClickActivate = async () => {
    const dateTo = dayjs().add(3, 'day')

    await dispatch(
      updateProfile(user.id, {
        ...profile,
        active_till: dateTo.format(),
      })
    )

    onSuccess()
  }

  return (
    <Popup
      visible={isVisible}
      bodyStyle={{
        height: '100vh',
        textAlign: 'center',
        overflowY: 'scroll',
        borderRadius: 0,
      }}
      onMaskClick={onClose}
    >
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '100%',
        }}
      >
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '0 -10px 10px',
            }}
          >
            <Button
              color="default"
              fill="none"
              style={{ color: 'var(--adm-color-text-secondary)' }}
            >
              Restore
            </Button>

            <Button
              color="default"
              fill="none"
              style={{ color: 'var(--adm-color-text-secondary)' }}
              onClick={onClose}
            >
              Not Now
            </Button>
          </div>
        </div>

        <div>
          <img src={img} alt="Premium mode" />
        </div>

        <div style={{ margin: '40px 0' }}>
          <Title level={1} tag="div" style={{ marginBottom: 15 }}>
            Premium mode
          </Title>

          <Title level={2} tag="div">
            Access to our best features
          </Title>

          <ul className={styles.list}>
            <li>Unlimited pages & folders</li>
            <li>Add & edit categories</li>
            <li>Advanced visual editor</li>
            <li>Document sign in</li>
          </ul>
        </div>

        <div>
          <Title level={4} tag="div" bold style={{ marginBottom: 40 }}>
            $9.49, billed weekly
          </Title>

          <Badge
            content="3 days trial"
            bordered
            wrapperStyle={{ display: 'block', width: '100%' }}
          >
            <Button
              color="primary"
              size="large"
              block
              onClick={handleClickActivate}
            >
              Activate for free
            </Button>
          </Badge>

          <Text color="secondary" style={{ marginTop: 30 }}>
            By purchasing, you agree to our{' '}
            <a style={{ color: 'var(--adm-color-text)' }}>Terms of Use</a> and{' '}
            <a style={{ color: 'var(--adm-color-text)' }}>Privacy Policy</a>.
            Cancel anytime for any reason
          </Text>
        </div>
      </Container>
    </Popup>
  )
}

export default PaywallPremium
