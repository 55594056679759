import React, { FC, useEffect, useRef, useState } from 'react'
import { Grid } from 'antd-mobile-v5'
import CategoryCard from '../CategoryCard/CategoryCard'
import { ICategory } from '../../types'
import AddCardButton from '../AddCardButton/AddCardButton'
import { createCategory, getCategory } from '../../store/actions/category'
import { useAppDispatch, useAppSelector } from '../../hooks'
import ModalFormCreate from '../Modal/ModalFormCreate/ModalFormCreate'
import { useAuth } from '../../hooks/useAuth'
import { useNavigate } from 'react-router'
import { setPaywall } from '../../store/reducers/paywall'
import PaywallPremium from '../Paywall/PaywallPremium/PaywallPremium'
import {
  getCountFolders,
  getProfile,
  updateProfile,
} from '../../store/actions/profile'
import dayjs from 'dayjs'
import PaywallUnlimitedAccess from '../Paywall/PaywallUnlimitedAccess/PaywallUnlimitedAccess'
import { getFolderList } from '../../store/actions/folder'
import PaywallPremiumUpdate from '../Paywall/PaywallPremiumUpdate/PaywallPremiumUpdate'

type PropsType = {
  list: ICategory[]
  isGrid?: boolean
  loading: boolean
}

const CategoryList: FC<PropsType> = ({ list, isGrid, loading }) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector((state) => state.profile)
  const [isModalCreate, setIsModalCreate] = useState<boolean>(false)
  const [isPaywallUnlimited, setIsPaywallUnlimited] = useState<boolean>(false)

  const handleUnlimitedAccessSuccess = async () => {
    setIsPaywallUnlimited(false)
    setIsModalCreate(true)
  }

  const handleOpenCreateModal = async () => {
    const countCreatedFolders = await dispatch(getCountFolders())

    if (
      countCreatedFolders < profile.folders ||
      dayjs(profile.active_till).diff(dayjs(), 'minute') > 0
    ) {
      setIsModalCreate(true)
    } else {
      setIsPaywallUnlimited(true)
    }
  }

  const handleCreate = async (value: string) => {
    await dispatch(createCategory(value, user.id))
  }

  const handleClick = async (id: string) => {
    await dispatch(getCategory(id))
    navigate(`/category/${id}`)
  }

  return (
    <>
      <Grid columns={isGrid ? 2 : 1} gap={15}>
        {list.map((item) => (
          <Grid.Item key={item.id}>
            <CategoryCard data={item} isGrid={isGrid} onClick={handleClick} />
          </Grid.Item>
        ))}

        {!loading && (
          <Grid.Item>
            <AddCardButton
              title="New Label"
              isGrid={isGrid}
              onClick={handleOpenCreateModal}
            />
          </Grid.Item>
        )}
      </Grid>

      <ModalFormCreate
        visible={isModalCreate}
        placeholder="Category Name"
        onSubmit={handleCreate}
        onClose={() => setIsModalCreate(false)}
      />

      <PaywallUnlimitedAccess
        isVisible={isPaywallUnlimited}
        onSuccess={handleUnlimitedAccessSuccess}
        onClose={() => setIsPaywallUnlimited(false)}
      />
    </>
  )
}

export default CategoryList
