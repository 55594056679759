import randomColor from 'randomcolor'
import { SortType, ICategory } from '../../types'
import { setPage } from '../reducers/page'
import {
  setCategoryList,
  addCategory,
  updateCategory,
  removeCategory,
} from '../reducers/category'
import { AppDispatchType } from '../index'
import { sortBy } from '../../helpers'
import { supabase } from '../../supabase'

const TABLE_NAME: string = 'categories'

export const getCategoryList =
  () =>
  async (dispatch: AppDispatchType): Promise<ICategory[]> => {
    try {
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .select()
        .order('required', { ascending: false })

      if (error) throw new Error(error.message)

      dispatch(setCategoryList(data))

      return data
    } catch (e) {
      console.log(e)
      return []
    }
  }

export const sortCategoryList =
  (sort: SortType) => async (dispatch: AppDispatchType, getState: any) => {
    const list = getState().category.categoryList
    const sorted = sortBy(list, 'title', sort)
    dispatch(setCategoryList(sorted))
  }

export const getCategory =
  (id: string) => async (dispatch: AppDispatchType) => {
    try {
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .select('id, color, title')
        .eq('id', id)
        .single()

      if (error) throw new Error(error.message)

      dispatch(setPage(data))
    } catch (e) {
      console.log(e)
    }
  }

export const createCategory =
  (title: string, user_id: string) => async (dispatch: AppDispatchType) => {
    try {
      const color = randomColor({
        luminosity: 'bright',
        alpha: 1,
        format: 'hex',
      })

      const { data, error } = await supabase
        .from(TABLE_NAME)
        .insert({
          color,
          title,
          user_id,
        })
        .select()
        .single()

      if (error) throw new Error(error.message)

      dispatch(addCategory(data))
    } catch (e) {
      console.log(e)
    }
  }

export const renameCategory =
  (id: string, title: string) => async (dispatch: AppDispatchType) => {
    try {
      const { error } = await supabase
        .from(TABLE_NAME)
        .update({ title })
        .eq('id', id)

      if (error) throw new Error(error.message)

      dispatch(updateCategory({ id, title }))
    } catch (e) {
      console.log(e)
    }
  }

export const deleteCategory =
  (id: string) => async (dispatch: AppDispatchType) => {
    const { error } = await supabase.from(TABLE_NAME).delete().eq('id', id)

    // todo delete files
    // const { data } = await supabase.storage
    //   .from('files')
    //   .list(`${user_id}/${id}`)
    //
    // const filesToRemove = data?.map((file) => `${user_id}/${id}/${file.name}`)
    //
    // if (filesToRemove) {
    //   await supabase.storage.from('files').remove(filesToRemove)
    // }

    if (error) console.log('error', error)

    dispatch(removeCategory(id))
  }
