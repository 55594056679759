import React, { FC } from 'react'
import styles from './ChoicePlan.module.scss'
import { Title, Text } from '../UI'
import { Badge } from 'antd-mobile-v5'

type ChoiceType = {
  value: string
  title: string
  caption: string
  badge?: string
}

type PropsType = {
  choices: ChoiceType[]
  value: string
  theme?: 'primary' | 'secondary'
  onChange: Function
}

const ChoicePlan: FC<PropsType> = ({
  choices,
  value,
  theme = 'primary',
  onChange,
}) => {
  return (
    <div className={styles.root}>
      {choices.map((item) => (
        <div
          key={item.value}
          className={styles.item}
          style={{
            backgroundColor:
              value === item.value
                ? theme === 'primary'
                  ? '#CCEEFF'
                  : '#fff'
                : 'transparent',
            borderColor:
              theme === 'primary' && value !== item.value
                ? 'rgba(32, 38, 62, 0.1)'
                : '#fff',
          }}
          onClick={() => onChange(item.value)}
        >
          <div className={styles.content}>
            <Title level={4} tag="div" bold>
              {item.title}
            </Title>
            <Text color="secondary">{item.caption}</Text>
          </div>

          <div className={styles.aside}>
            {item.badge && (
              <Badge
                className={styles.badge}
                color="white"
                content="Save 68%"
                bordered
              />
            )}

            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {value === item.value ? (
                <>
                  <circle cx="12" cy="12" r="12" fill="#02AAFF" />
                  <path
                    d="M8 12L11 16L16 8"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </>
              ) : (
                <circle
                  cx="12"
                  cy="12"
                  r="11.25"
                  stroke="#02AAFF"
                  strokeWidth="1.5"
                />
              )}
            </svg>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ChoicePlan
